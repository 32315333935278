import imageWithText1 from '../assets/images/about-image-1.jpg'

const ImageWithTextAbout1 = () => {
    return (
        <>
            <section className="image-with-text section">
                <div className="page-wrapper">
                    <div className="image-with-text-block">
                        <div className="image">
                            <img src={ imageWithText1 } alt="" loading="lazy" />
                        </div>
                        <div className="page-content-block">
                            { /*<div className="intro">COMPANY</div> */ }
                            <h2>Take Charge of Your Online Security</h2>
                            <p>At CyberAlerts, we are committed to safeguarding your digital world by providing comprehensive cybersecurity solutions for individuals and families. In today’s ever-evolving cyber landscape, protecting your personal information and digital assets is more important than ever. That’s why we offer round-the-clock monitoring, technical support, and real-time threat detection to ensure your safety online.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ImageWithTextAbout1