const MissionAbout = () => {
    return (
        <>
            <section className="flexbox section small-padding-section">
                <div className="page-wrapper">
                    <div className="custom-block-style-2 bg-1 box-style-1">
                        <div className="styled-content">
                            { /* <h2>Our mission</h2>*/ }
                            <p className="big">Understand, Assess, and Assure</p>
                            <p>We believe that everyone deserves peace of mind when navigating the online realm. Our mission is to empower you with the tools and knowledge necessary to defend against cyber-attacks, data breaches, and identity theft. Through continuous security assessments and real-time monitoring, CyberAlerts provides unparalleled visibility into your personal security posture.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default MissionAbout