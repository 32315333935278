import { useEffect, useState } from "react";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import IconCloseBlue from "../assets/svg/icon-close-blue";
import IconFilters from "../assets/svg/icon-filters";
import { Form } from "react-router-dom";
import IconAlerts from "../assets/svg/icon-alerts";
import { AssetProtectionCountFilter, clearProtectionFilter, fetchData, mongodbURL, protectionShowFilter, showRecentReports } from "../helper";
import IconArrowRight from "../assets/svg/icon-arrow-right";


const AlertsPage = () =>{

    const [alerts, setAlerts] = useState();
    const [datax, setDatax] = useState([]);
    const [datai, setDatai] = useState([]);

    const [pageNum, setPageNum] = useState();

    const getRecentReports = async(page, assetTypes = 'all', dateRange = '7-days', identity = 'all', assetStatus = 'active', unset = 2) => {

        console.log('assetTypes', assetTypes)
        console.log('dateRange', dateRange)
        console.log('identity', identity)
        console.log('assetStatus', assetStatus)

        let email = fetchData('userName')
        let result = await fetch(
        mongodbURL + 'getuser-risk-activities-precise', {
            method: "post",
            body: JSON.stringify({ email, page, assetTypes, dateRange, identity, assetStatus }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();

        if( unset === '1'){
            console.log('setting to empty')
            datai.splice(0,datai.length)
            datax.splice(0,datax.length)
        }
        console.warn(result);

        for(let ia = 0; ia < result.data.length;ia++){
            if( !datai.includes(result.data[ia]._id) ){
                datai.push(result.data[ia]._id)
                datax.push(result.data[ia]);
                console.log('added', result.data[ia].value)
            } else {
                console.log('in array', result.data[ia].value)
            }
        }
        setAlerts(datax)
        setPageNum(result.nextPage)

        console.log(datax)
        document.querySelector('#user-assets-protection-layout').classList.add('loaded')
        document.querySelector('#show-more-alerts').classList.add('loaded')

        if( result.nextPage !== result.currentPage) {
            document.querySelector('#show-more-alerts').classList.remove('hide-on-first-load')
        } else {
            document.querySelector('#show-more-alerts').classList.add('hide-on-first-load')
        }
    }

    const handleChange = (e) => {
        let selectAssetType = document.querySelector('#asset-type').value
        let value = document.querySelector('#search').value.toLocaleLowerCase()
        let count = 0

        if( value.length === 0){
            document.querySelector('#protection-assets-holder').classList.add('hide')
            document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.remove('hide') )
        } else {
            document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.add('hide') )
            document.querySelector('#protection-assets-holder').classList.add('hide')
            document.querySelectorAll('.asset-title-search').forEach(el => {
                if( el.textContent.toLocaleLowerCase().includes(value) ){
                    if( selectAssetType === 'all'){
                        count++
                        el.closest('.user-assets-protection-single').classList.remove('hide')
                    } else {
                        if( el.closest('.user-assets-protection-single.asset-type-' + selectAssetType) ){
                            count++
                            el.closest('.user-assets-protection-single.asset-type-' + selectAssetType).classList.remove('hide')
                        }
                    }
                }
            })

            if( count === 0 ){
                document.querySelector('#protection-assets-holder').classList.remove('hide')
            } else{
                document.querySelector('#protection-assets-holder').classList.add('hide')
            }
        }
    }

    function calculateScore(counts){
        let returnCount = 0
        if( counts >= 1 && counts <= 10 ){
            returnCount = Math.round( ( counts / 100) * 1)
        } else if( counts >= 11 && counts <= 100 ){
            returnCount = Math.round( ( counts / 100) * 10)
        } else if( counts >= 101 && counts <= 1000 ){
            returnCount = Math.round( ( counts / 1000) * 10)
        } else if( counts >= 1001 && counts <= 10000 ){
            returnCount = 10
        }
        return returnCount
    }
    function calculateRisk(risktype){
        let riskType = ''
        console.log(risktype)
        if( risktype === 1){
            riskType = '<div class="d-alert-level high-risk">High Risk</div>'
        } else if( risktype === 1){
            riskType = '<div class="d-alert-level low-risk">Low Risk</div>'
        } else{
            riskType = '<div class="d-alert-level low-risk">Low Risk</div>'
        }
        return riskType;
    }

    const showMoreAlerts = async(e) => {
        e.preventDefault();
        document.querySelector('#show-more-alerts').classList.remove('loaded')
        let page = document.querySelector('#show-more-alerts-btn').getAttribute('data-value')
        let assetType = document.querySelector('#filter-asset-type').value
        let dateRange = document.querySelector('#filter-date-range').value
        let identity = document.querySelector('#filter-identity').value
        let assetStatus = document.querySelector('#filter-asset-status').value
        showRecentReports(1,assetType,dateRange,identity,assetStatus)
    }

    const applyFiltersFunction = (e) => {
        e.preventDefault();

        document.querySelector('#user-assets-protection-layout').classList.remove('loaded')

        let assetType = document.querySelector('#filter-asset-type').value
        let dateRange = document.querySelector('#filter-date-range').value
        let identity = document.querySelector('#filter-identity').value
        let assetStatus = document.querySelector('#filter-asset-status').value
        let unset = 1

        document.querySelector('#show-more-alerts-btn').setAttribute('data-value', 1)
        showRecentReports(1,assetType,dateRange,identity,assetStatus, unset)

        

        let FilterCount = AssetProtectionCountFilter();

        document.querySelector('#protection-filter .value').innerText = FilterCount.count
        if( FilterCount.count > 0 ){
            document.querySelector('#protection-filter').classList.remove('empty')
            document.querySelector('#form-filter-buttons').classList.remove('hide-element')
        } else {

            document.querySelector('#form-filter-buttons').classList.add('hide-element')
            document.querySelector('#protection-filter').classList.add('empty')
        }

        document.querySelector('#protection-filter-blocks').classList.add('hide-element');
    }
    
    useEffect(() => {
        //setPageNum(1)
        //getRecentReports(1)
        showRecentReports()
    },[]);

    return (
        <>
        <DashboardHeader active="alerts" />
        <div className="d-wrapper">
            <DashboardPageTitle title="Alerts" />
            <div className="d-block classic-d-block full-width alert-bubble-block" style={{ display: 'none'}}>
                <div className="d-block d-alert-block">
                    <div className="wrap has-sticky-link">
                        <a href="/" className="alert-closer"><IconCloseBlue /></a>
                        <h4>How to get the most out of your Security Alerts </h4>
                        <p>There are a few things you can do to get the most out of your CyberAlerts plan so you prevent breaches faster</p>
                        <div className="sticky-link classic-link"><a href="/">Learn More</a></div>
                    </div>
                </div>
                <div className="d-block d-alert-block">
                    <div className="wrap has-sticky-link">
                        <a href="/" className="alert-closer"><IconCloseBlue /></a>
                        <h4>Set up Push Notifications</h4>
                        <p>There are a few things you can do to get the most out of your CyberAlerts plan so you prevent breaches faster</p>
                        <div className="sticky-link classic-link"><a href="/">Set up now</a></div>
                    </div>
                </div>
            </div>

            <div className="d-block d-asset-protection-block layout-2">
                <div className="wrap">
                    <div className="d-block-header">
                        <h3><IconAlerts /> Recent Reports</h3>
                        <div id="protection-filter" className="button-style-layout empty" onClick={ protectionShowFilter }>
                            <span className="icon"><IconFilters /></span>
                            <span className="label">Filters</span>
                            <span className="value">1</span>
                        </div>
                    </div>
                    <Form>
                    <div id="protection-filter-blocks" className="form-filter-blocks hide-element">
                            <h5>Select your filters</h5>
                            <a href="/" className="filter-close" onClick={ protectionShowFilter }><IconCloseBlue /></a>
                            <div className="form-actions">
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-asset-type">Asset type</label>
                                        <select name="filter-asset-type" id="filter-asset-type">
                                            <option value="all">All</option>
                                            <option value="email">Email</option>
                                            <option value="phone">Phone</option>
                                            <option value="password">Password</option>
                                            <option value="person">Person</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-date-range">Date Range</label>
                                        <select name="filter-date-range" id="filter-date-range">
                                            <option value="all" selected>Display All</option>
                                            <option value="today">Today</option>
                                            <option value="last-7-days">Last 7 Days</option>
                                            <option value="last-week">Last Week</option>
                                            <option value="this-month">This Month</option>
                                            <option value="last-30-days">Last 30 days</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions">
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-identity">Identity</label>
                                        <select name="filter-identity" id="filter-identity">
                                            <option value="all">All</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-asset-status">Asset Status</label>
                                        <select name="filter-asset-status" id="filter-asset-status">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions-button">
                                <a id="p-apply-filter-btn" href="/dashboard/protection" className="apply-filter-btn btn" onClick={ applyFiltersFunction }>Apply Filters</a> <a href="/dashboard/alerts">Clear all Filters</a>
                            </div>
                        </div>
                        
                        <div className="form-actions">
                            <div className="form-custom-dropdown">
                                <div className="form-custom-dropdown-wrap">
                                    <label htmlFor="asset-type">Search asset type</label>
                                    <select name="asset-type" id="asset-type" onChange={ handleChange }>
                                        <option value="all">All</option>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                        <option value="password">Password</option>
                                        <option value="identity">Person</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-searchbar">
                                <input type="search" name="search" id="search" placeholder="Search assets" onChange={ handleChange } />
                            </div>
                        </div>
                        <div id="form-filter-buttons" className="form-filter-buttons hide-element">
                            <div className="label-block">Result filtered by</div>
                            <div id="form-filter-buttons-wrap" class="form-filter-button-wrap">
                                <span class="filter-button hide-element" id="protection-filter-block-1" onClick={ clearProtectionFilter }>
                                    <span class="label">Asset Type - Email</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-2" onClick={ clearProtectionFilter }>
                                    <span class="label">Date Range - Last 7 Days</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-3" onClick={ clearProtectionFilter }>
                                    <span class="label">Identity - Inactive</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-4" onClick={ clearProtectionFilter }>
                                    <span class="label">Status - Inactive</span>
                                    <span class="icon"></span>
                                </span>
                            </div>
                        </div>
                        <div className="user-assets-protection-layout position-relative" id="user-assets-protection-layout">


                            { alerts && alerts.map((item) => {
                                let riskType = '', riskTypeLabel = ''
                                let itemlink = '/dashboard/alerts/reports/'+ item._id +'/'+ item.assetid +'/1'
                                let itemtype = 'icon asset-type-' + item.type
                                let userClass = 'user-assets-protection-single asset-type-password risk-type-' + item.risktype
                                let score = calculateScore(item.counts)

                                if( score >= 0 && score <= 3){
                                    riskType = 'risk-type low'
                                    riskTypeLabel = 'Low Risk'
                                }
                                if( score >= 4 && score <= 7){
                                    riskType = 'risk-type med'
                                    riskTypeLabel = 'Med Risk'
                                }
                                if( score >= 8 && score <= 10){
                                    riskType = 'risk-type high'
                                    riskTypeLabel = 'High Risk'
                                }


                                return(
                                    <>
                                    <div className={ userClass }>
                                    <div className="usp-view-report-area size-1">
                                    <div className="usp-view-report-top">
                                    <div className={ itemtype }></div>
                                    <div className="label">
                                    <div className="title asset-title-search">{ item.value }</div>
                                    <div className="detail">{ item.nickname }</div>
                                    </div>
                                    </div>
                                    
                                    <div className="usp-view-report-bottom">
                                    <span className="view-report">{ item.counts } Records Found</span>
                                    </div>

                                    </div>
                                    <div className="usp-view-risk-area size-1">
                                    <div className="value">{ score }/10</div>
                                    <div class={ riskType }>{ riskTypeLabel }</div>
                                    </div>
                                    <div className="usp-view-risk-area size-1">
                                    <div className="last-scanned text-center">
                                    <div>{ new Date(item.date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }</div>
                                    <div>{ new Date(item.date).toLocaleDateString("en-US", { month: 'short', day: 'numeric', year: 'numeric' }) }</div>
                                    </div>
                                    </div>
                                    <div className="usp-view-clean-area full-btn size-1"><a href={ itemlink } className="clean-this-arrow"><IconArrowRight /></a></div>
                                    </div>
                                    </>
                                )
                            })
                            }
                            
                            <div className="loader-spinner">
                                <span className="loader big"></span>
                            </div>
                            <h4 id="protection-assets-holder" className="hide">No Reports Found</h4>
                        </div>

                        <div id="show-more-alerts" className="hide-on-first-load flex-wrap flex-align-center flex-justify-center loaded position-relative">
                            <a href="/dashboard/alerts" id="show-more-alerts-btn" data-value="1" data-page={ pageNum } className="dashboard-btn" onClick={ showMoreAlerts }>Show More Alerts</a>
                            <div className="loader-spinner">
                                <span className="loader big"></span>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>

        </div>
        </>
    );
}
export default AlertsPage