

import { useEffect } from "react";
import AdminDashboardHeader from "./AdminDashboardHeader";
import AdminDashboardPageTitle from "./AdminDashboardTitle";

const AdminDashboard = () =>{

    useEffect(() => {
    },[]);
    return (
        <>
        <AdminDashboardHeader active="home" />
        <div className="d-wrapper">
            <AdminDashboardPageTitle title="Overview" />
        </div>
        </>
    );
}
export default AdminDashboard