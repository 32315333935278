

import { useEffect, useState } from "react";
import AdminDashboardHeader from "./AdminDashboardHeader";
import AdminDashboardPageTitle from "./AdminDashboardTitle";
import { addPostbackURLShaving, fetchData, mongodbURL } from "../helper";

const AdminPostBackURLShaving = () =>{

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let id = fetchData("id");
                let response = await fetch(mongodbURL + "postback-track-get", {
                    method: "POST",
                    body: JSON.stringify({ id }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                let result = await response.json();
                setUserData(result); // Store result in state
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    },[]);
    return (
        <>
        <AdminDashboardHeader active="postback-shaving" />
        <div className="d-wrapper">
            <div className="admin-header">
                <AdminDashboardPageTitle title="Postback URL Shaving" />
                <a href="/" id="add-new-asset-btn" className="button btn blue-btn small" onClick={ addPostbackURLShaving }>Add Postbar URL Shaving</a>
            </div>
            <div className="d-block email-report-page layout-2">
                <div className="wrap position-relative">
                    <ul id="shaving-ul-list" className="shaving-ul-list">
                        <li>
                            <span className="shaving-list">
                                <span className="title">subID</span>
                                <span className="shaving">Shaving</span>
                            </span>
                        </li>
                        {userData?.postbackdata?.map((item) => (
                            <li key={item._id}>
                                <a href={`/admin/postback-shaving/${item._id}`} className="shaving-list">
                                    <span className="title">{item.subid}</span>
                                    <span className="shaving">{item.shaving}%</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}
export default AdminPostBackURLShaving