
const AdminDashboardPageTitle = ({ title, show, extraclasses }) =>{


    extraclasses = 'd-block page-title-block  flex-wrap flex-align-center flex-justify-between ' + extraclasses
    return (
        <>
        <div className={ extraclasses }>
            <h2 className={ show }>{ title }</h2>
        </div>
        </>
    );
}
export default AdminDashboardPageTitle