import { useEffect } from "react";
import Nav from "../sections/Nav";
import FAQStyle2 from "../sections/FAQStyle2";
import BannerHomepage2 from "../sections/BannerHomepage2";
import Footer from "../sections/Footer";
import PlansAndPricing from "../sections/PlansAndPricing";

const Pricing = ({ classes }) =>{

    useEffect(() => {
        document.title = "Pricing - Protect Your Identity with CyberAlerts";
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
            <div className={ classes }>
                <Nav />
                <PlansAndPricing />
            </div>
            <FAQStyle2 classes="section flexbox flexbox-spacing-2 faq-blocks large-padding-section" />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default Pricing