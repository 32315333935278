
import { useEffect } from "react";
import logo from "../assets/images/logo.png";
import FooterLogo1 from "../assets/images/checkout-footer-logo-1.png"
import FooterLogo2 from "../assets/images/checkout-footer-logo-2.png"
import FooterLogo3 from "../assets/images/checkout-footer-logo-3.png"
import FooterLogo4 from "../assets/images/checkout-footer-logo-4.png"
import FooterLogo5 from "../assets/images/checkout-footer-logo-5.png"
import FooterLogo6 from "../assets/images/checkout-footer-logo-6.png"
import FooterLogo7 from "../assets/images/checkout-footer-logo-7.png"
import FooterLogo8 from "../assets/images/checkout-footer-logo-8.png"
import ThankYouVisa from "../assets/images/thankyou-visa.png"
import { deleteItem, fetchData, mongodbURL } from "../helper";

async function setUserDataFromURL(request){
    let result = await fetch(
        mongodbURL + 'update-user-account-details', {
            method: "post",
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    result = await result.json();
    if( result.user === 'valid' ){
        localStorage.setItem("userName", result.email)
        localStorage.setItem("id", result._id)
        localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
        localStorage.setItem('userStatus', result.status )
        localStorage.setItem('account-last-scanned', result.datemodified )
        if( result.image ){
            localStorage.setItem('profile-image', '/uploaded/' + result.image )
        } else {
            localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
        }
        document.querySelectorAll('.login-link-text').forEach(a => {
            a.href = '/dashboard'
        });

        setTimeout( function(){
            //window.location.href = '/dashboard';
        }, 5000);
        
    } else {
        deleteItem('userName');
        deleteItem('id');
        deleteItem('userFullname');
        deleteItem('userData');
        deleteItem('userStatus');
        deleteItem('account-last-scanned');
    }
}

async function sendPostBackCID(cid, email, subID) {

    let result = await fetch(
        mongodbURL + 'postback-track', {
            method: "post",
            body: JSON.stringify({ cid : cid, email : email, subID : subID }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    result = await result.json();
  
    console.log(result)
}

const CheckoutThankYou = ({ classes }) =>{

    let subscription_id = new URLSearchParams(window.location.search).get("subscription_id");
    let ba_token = new URLSearchParams(window.location.search).get("ba_token");
    let token = new URLSearchParams(window.location.search).get("token");
    let email = fetchData('userName')


    let runOnce = 1
    useEffect(() => {
        if( runOnce === 1){
            sendPostBackCID( fetchData('cid'), fetchData('userName'), fetchData('subid'), );
            setUserDataFromURL({ email : email, subscription_id : subscription_id, ba_token : ba_token, token : token });
        }
        runOnce++
    });

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
            <div className={ classes }>
                <div id="page-container" className="checkout-page">
                    <div className=" radial4-bg lazyload-bg">
                        <header id="header">
                            <div className="wrapper flex-wrap flex-justify-between flex-align-center">
                                <div className="header-logo flex-wrap flex-align-center">
                                <a href="/" className="logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></a>
                                </div>
                                <div className="btn-flex-holder flex-align-center with-login-link col-gap-30">
                                    <a className="login-link-text" href="/login">Login</a>

                                    <a href="/contact" className="get-help btn blue-btn">
                                        <span>Get Help</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </header>

                        <section className="section checkout-section thank-you">
                            <div className="wrapper">
                                <div className="thank-you-block">
                                    <div className="thank-you-block-header text-center">
                                        <h2 className="thank-you-title ">Thank You!</h2>
                                        <div className="thank-you-confirmation-code">Your account was successfuly created</div>
                                        <div className="thank-you-icon-status">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_727_1406)">
                                            <path d="M49 25C49 38.2548 38.2548 49 25 49C11.7452 49 1 38.2548 1 25C1 11.7452 11.7452 1 25 1C38.2548 1 49 11.7452 49 25Z" stroke="#1773B0" strokeWidth="2"/>
                                            <path d="M15 24.5113L22.3067 31.818L35.1247 19" stroke="#1773B0" strokeWidth="2"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_727_1406">
                                            <rect width="50" height="50" fill="white"/>
                                            </clipPath>
                                            </defs>
                                            </svg>

                                        </div>
                                        <div className="thank-you-order-content" style={{ display: 'none' }}>
                                            <p><strong>Your order is confirmed</strong></p>
                                            <p>We’ve accepted your order, and we’re getting it ready. Come back to this page for updates on your shipment status.</p>
                                        </div>
                                    </div>

                                    <div className="thank-you-customer-info">
                                        <h3>Login and complete your profile</h3>
                                        <p>Check your email for your login details</p>
                                    </div>

                                    <div className="thank-you-customer-info" style={{ display: 'none' }}>
                                        <div className="customer-info-title">Customer Information</div>
                                        <div className="flex-wrap col-gap-15">
                                            <div className="one-half">
                                                <div className="customer-info-block">
                                                    <div className="c-info-title">Contact Information</div>
                                                    <div className="customer-email">name@example.com</div>
                                                </div>
                                            </div>
                                            <div className="one-half">
                                                <div className="customer-info-block">
                                                    <div className="c-info-title">Payment method</div>
                                                    <div className="customer-payment-method"><img src={ ThankYouVisa } alt="" className="thank-you-card" /> ending with 4242 - <strong>$336.80</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-wrap col-gap-15">
                                            <div className="one-half">
                                                
                                                <div className="customer-info-block">
                                                    <div className="c-info-title">Shipping address</div>
                                                    <div className="customer-name">John Smith</div>
                                                    <div className="customer-address1">151 O’Connor St</div>
                                                    <div className="customer-address2">Ground floor</div>
                                                    <div className="customer-city-state-zip">Ottawa ON K2P 2L8</div>
                                                    <div className="customer-country">Canada</div>
                                                </div>
                                            </div>
                                            <div className="one-half">
                                                
                                                <div className="customer-info-block">
                                                    <div className="c-info-title">Billing address</div>
                                                    <div className="customer-name">John Smith</div>
                                                    <div className="customer-address1">151 O’Connor St</div>
                                                    <div className="customer-address2">Ground floor</div>
                                                    <div className="customer-city-state-zip">Ottawa ON K2P 2L8</div>
                                                    <div className="customer-country">Canada</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="flex-wrap col-gap-15">
                                            <div className="one-half">
                                                <div className="customer-info-block">
                                                    <div className="c-info-title">Shipping method</div>
                                                    <div className="customer-email">Digital Delivery</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="thank-you-customer-info-save" style={{ display: 'none' }}>
                                        <label><input type="checkbox" /><span className="checkbox"></span>Save my information for a faster checkout</label>
                                    </div>

                                    <div className="thank-you-custom-back-btn">
                                        <a href="/login" className="login-link-text skip-link btn btn-blue">
                                            <span>Open CyberAlerts Dashboard</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <footer id="main-footer-2" className="section flexbox small-padding-section">
                    <div className="page-wrapper-1170">
                        <div className="page-content-block flex-align-center flex-justify-center med-gap">
                            <div className="header-logo flex-wrap flex-align-center flex-justify-center">
                                <a href="/" className="logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></a>
                            </div>
                            <div className="footer-links"><a href="/acceptable-use" target="_blank">Acceptable Use</a>   |  <a href="https://cyberalerts.net/privacy-policy">Privacy Policy</a> | <a href="https://cyberalerts.net/terms-and-conditions">Terms of Use</a></div>
                            <div className="footer-copyright">Copyright &copy; {(new Date().getFullYear())} CyberAlerts Inc. All Rights Reserved.</div>
                            <div className="footer-images flex-wrap flex-align-center flex-justify-center col-gap-15">
                                <img src={ FooterLogo1 } alt="" loading="lazy" />
                                <img src={ FooterLogo2 } alt="" loading="lazy" />
                                <img src={ FooterLogo3 } alt="" loading="lazy" />
                                <img src={ FooterLogo4 } alt="" loading="lazy" />
                            </div>
                            <div className="footer-images flex-wrap flex-align-center flex-justify-center col-gap-15 footer-payments-images">
                                <img src={ FooterLogo5 } alt="" loading="lazy" />
                                <img src={ FooterLogo6 } alt="" loading="lazy" />
                                <img src={ FooterLogo7 } alt="" loading="lazy" />
                                <img src={ FooterLogo8 } alt="" loading="lazy" />
                            </div>
                            <div className="footer-checkout-disclaimer text-center">
                                <p>*$1 Million ID Theft Coverage – provides up to $1 million in coverage for: funds stolen by unauthorized electronic funds transfer from an account in your name, legal fees, miscellaneous expenses, and up to $1,500 per week (five weeks maximum) for wages lost while resolving a stolen identity event. Family members means the enrollee’s children under the age of twenty-four (24) who permanently live in the same residence as the enrollee at the time of the stolen identity event. Underwritten by AIG.</p>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        </>
    );
}
export default CheckoutThankYou