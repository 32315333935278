import { useEffect } from "react";

const FAQBlock = ({ classes }) =>{
    const faqClick = (e) =>{
        e.target.closest('.faq-block').classList.toggle('active')
        console.log(e);
    }

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );


    return (
        <>
        <section id="" className={ classes }>
            <div className="wrapper">
                <div className="content-block text-center small-wrapper-auto">
                    <div className="intro">Frequently Asked Questions</div>
                    <h2 className="small-wrapper-auto">Everything you need to know about the product and billing.</h2>
                </div>
                <div className="faq-accord">
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">What is CyberAlerts?
                            </div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>CyberAlerts is a dark web monitoring subscription service that helps protect your personal and financial information. We scan the dark web for compromised credentials, leaked data, and potential threats, alerting you in real time so you can take action before it's too late.

                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How do I use CyberAlerts?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Sign up for the CyberAlerts 24-hour trial and set up your account. Once registered, you’ll receive alerts via email whenever your data appears on the dark web. Our dashboard allows you to track breaches, monitor sensitive information, and take recommended security actions to protect your identity.

                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">What are the benefits of CyberAlerts?
                            </div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <ul>
                                <li>Dark Web Monitoring – Continuous scanning of the dark web to detect leaked personal and financial data.</li>
                                <li>Real-Time Alerts – Instant notifications if your data is found in a breach.</li>
                                <li>Security Recommendations – Actionable steps to secure your information.</li>
                                <li>Identity Protection – Minimize risks of fraud, identity theft, and cybercrime.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How much does CyberAlerts cost?
                            </div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>CyberAlerts offers a 24-hour trial for just $1, followed by $19.99 per month for ongoing dark web monitoring and protection.
                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How do I cancel my CyberAlerts subscription?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>To cancel your CyberAlerts subscription, email support@cyberalerts.net and our customer support team will process your request within 24-48 hours.

                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How do I request a refund?
                            </div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>If you're not satisfied with CyberAlerts and would like to request a refund, email support@cyberalerts.net, and our support team will review your request within 24-48 hours.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default FAQBlock