import React, { useState } from 'react';
import TwoFactorAuthPrompt from '../sections/TwoFactorPrompt';
import { mongodbURL } from '../helper';
import { jwtDecode } from 'jwt-decode';
import { Form, Link } from 'react-router-dom';
import logo from "../logo.png";
import safecureimage1 from "../assets/images/safe-secure-1.png";
import safecureimage2 from "../assets/images/safe-secure-2.png";
import safecureimage3 from "../assets/images/safe-secure-3.png";
import safecureimage4 from "../assets/images/safe-secure-payments.png";

const Login2 = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const loginClick = async () => {
        const response = await fetch(mongodbURL + '2fa/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });
        const result = await response.json();

        console.log('login', result);

        if (result.success) {

            localStorage.setItem('lastLoginEmail', username);

            let getRememberToken = localStorage.getItem('trustedDeviceToken');

            //console.log(getRememberToken);

            let show2FA = false

            try {
                const decoded = jwtDecode(getRememberToken);
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                if (decoded.exp && decoded.exp < currentTime) {
                    show2FA = true
                    console.log('token expire')
                }
            } catch (err) {
                show2FA = true
                console.log('invalid token')
            }

            console.log('show2FA', show2FA)

            if( result.twoFactor !== 'enabled'){
                show2FA = false
            }

            if( show2FA ){
                setIsLoggedIn(true);
            } else{
                if( result.login_status === 'success' ){
                    localStorage.setItem("userName", result.email)
                    localStorage.setItem("id", result._id)
                    localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
                    localStorage.setItem('userStatus', result.status )
                    localStorage.setItem('account-last-scanned', result.datemodified )
                    if( result.image ){
                        localStorage.setItem('profile-image', '/uploaded/' + result.image )
                    } else {
                        localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
                    }
                    console.log('redirect to dashboard');
                    window.location.href = '/dashboard';
                } else if( result.login_status === 'first-login-required' ){
                    localStorage.setItem("userName", result.email)
                    localStorage.setItem("id", result._id)
                    localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
                    localStorage.setItem('userStatus', result.status )
                    localStorage.setItem('account-last-scanned', result.datemodified )
                    if( result.image ){
                        localStorage.setItem('profile-image', '/uploaded/' + result.image )
                    } else {
                        localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
                    }
                    window.location.href = '/dashboard';
                }
            }

        } else {
            document.querySelector('#response-text').innerText = result.message;
        }
    };

    const verify2FA = async (code, rememberDevice, qrSecret) => {
        const response = await fetch(mongodbURL + 'login/verify', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password,
                totpCode: code,
                secret : document.querySelector('#qrSecret').value,
                rememberDevice,
            }),
        });

        const result = await response.json();

        if (result.success) {
            console.log(result)
            if (result.trustedDeviceToken) {
                localStorage.setItem('trustedDeviceToken', result.trustedDeviceToken);
            }
            if( result.login_status === 'success' ){
                localStorage.setItem("userName", result.email)
                localStorage.setItem("id", result._id)
                localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
                localStorage.setItem('userStatus', result.status )
                localStorage.setItem('account-last-scanned', result.datemodified )
                if( result.image ){
                    localStorage.setItem('profile-image', '/uploaded/' + result.image )
                } else {
                    localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
                }
                console.log('redirect to dashboard');
                window.location.href = '/dashboard';
            } else if( result.login_status === 'first-login-required' ){
                localStorage.setItem("userName", result.email)
                localStorage.setItem("id", result._id)
                localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
                localStorage.setItem('userStatus', result.status )
                localStorage.setItem('account-last-scanned', result.datemodified )
                if( result.image ){
                    localStorage.setItem('profile-image', '/uploaded/' + result.image )
                } else {
                    localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
                }
                window.location.href = '/dashboard';
            }
        } else {
            document.querySelector('#response-text').innerText = result.message;
        }
    };

    return (
        <div>

        <div id="page-container" className="login-page">
                <div className="login-container">
                    <div className="logo-block"><Link to="/" className="logo"><img loading="lazy" src={logo} alt="" className="img-as-block" /></Link></div>
                    <div className="logo-form-wrapper">
                    {!isLoggedIn ? (
                        <div>
                            <h3 className="text-center">Log in to your account</h3>
                            <div className="form-block">
                                <p id="response-text"></p>
                                <div className="form-field">
                                    <div className="form-field-label">
                                        <label htmlFor="username">Email Address</label>
                                    </div>
                                    <input
                                        type="text"
                                        className="input-text"
                                        placeholder="Enter email address"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>

                                <div className="form-field">
                                    <div className="form-field-label flex-wrap flex-justify-between">
                                        <label htmlFor="password">Password</label>
                                        <a href="/forgotpassword" className="forgot-password">Forgot Password</a>
                                    </div>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        className="input-text"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className="form-field submit">
                                    <button className="btn blue-btn full-btn" onClick={loginClick}><span>Sign in</span></button>
                                </div>

                                <div className="form-field text-center dont-have-account">
                                    Don’t have an account? <a href="/pricing">Sign Up</a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <TwoFactorAuthPrompt onVerify={verify2FA} />
                    )}
                    </div>
                    <div className="secure-images" style={{ display : 'none' }}>
                        <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                            <img loading="lazy" src={ safecureimage1 } alt="" className="img-as-block"/>
                            <img loading="lazy" src={ safecureimage2 } alt="" className="img-as-block"/>
                            <img loading="lazy" src={ safecureimage3 } alt="" className="img-as-block"/>
                        </div>
                        <div className="flex-wrap flex-justify-center flex-align-center col-gap-30">
                            <img loading="lazy" src={ safecureimage4 } alt="" className="img-as-block"/>
                        </div>
                    </div>
                    <div className="text-center login-copyright">
                        <div>This site is protected by reCAPTCHA and the Google <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms of Service</a> apply.</div>
                        <div className="login-copyright-text">Copyright &copy; {(new Date().getFullYear())} CyberAlerts Inc. All Rights Reserved.</div>
                    </div>
                </div>
            </div>

            


        </div>
    );
};

export default Login2;
