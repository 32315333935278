import { useEffect } from "react";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import {  getSingleAsset, showDeleteYesOrNo } from "../helper";
import { Form, useParams } from "react-router-dom";
import IconArrowLeft from "../assets/svg/icon-arrow-left";



const AssetSinglePage = () =>{

    let params = useParams();
    
    useEffect(() => {
        getSingleAsset(params.id)
        document.querySelector('#modal-popup-assetid').value = params.id
    } );

    return (
        <>
        <input type="hidden" value="" name="modal-popup-assetid" id="modal-popup-assetid" />
        <DashboardHeader active="assets" />
        <div className="d-wrapper">
            <DashboardPageTitle title="" show="hide" />
            <div className="back-to-previous-page">
                <a href="/dashboard/assets"><IconArrowLeft /> Back to Assets</a>
            </div>
            <div className="d-block classic-d-block dashboard-change-password layout-full-width position-relative">
                <div className="wrap">
                    <div className="loader-spinner">
                        <span className="loader big"></span>
                    </div>
                    <Form className="dashboard-forms">
                        <div className="d-block-header">
                            <h2><span id="page-title-bar"></span></h2>
                        </div>
                        <div className="d-block-plan-block">
                            <div className="title">Details:</div>
                            <div className="table-row-setup">
                                <div className="label">Asset</div>
                                <div className="value-bar">
                                    <span id="single-asset-score" className="score">0/10</span>
                                    <span id="single-asset-score-val" className="bar" data-score="0"></span>
                                </div>
                            </div>
                            <div className="table-row-setup">
                                <div className="label">Risk:</div>
                                <div className="value-bar">
                                    <div id="single-risk-value"></div>
                                </div>
                            </div>
                            <div className="table-row-setup">
                                <div className="label">Last Scanned:</div>
                                <div className="value-bar">
                                    <div id="single-last-scanned" className="last-scanned"></div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="dashboard-form-field submit-field">
                            <div className="field flex-wrap flex-align-center flex-justify-center">
                                <button type="button" name="delete" className="delete" id="delete" onClick={ showDeleteYesOrNo }>Delete</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

        </>
    );
}
export default AssetSinglePage