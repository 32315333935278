import { Form, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import '../dashboard/assets/dashboard.css'
import '../dashboard/assets/dashboard-responsive.css'
import { useEffect, useState } from "react";
import { fetchData, ModalClose, mongodbURL } from "../helper";
import IconCloseBlue from "../assets/svg/icon-close-blue";
export function AdminLayoutLoader(){
    return 'test'
}


async function formSubmit(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerHTML = ''

    let subID = document.querySelector('#subID').value
    let shavingPercent = document.querySelector('#shaving-percent').value

    let email = fetchData('userName')
    let result = await fetch(
        mongodbURL + 'postback-track-add', {
            method: "post",
            body: JSON.stringify({ email, subID, shavingPercent }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    result = await result.json();
    console.warn(result);

    if( result ){
        e.target.closest('form').querySelector('.asset-placeholder-response').innerHTML = result.message
    }
    if( result.success ){
        document.querySelector("#shaving-ul-list").innerHTML += `
        <li>
            <a href="/admin/dashboard/${ result.postback.insertedId }" class="shaving-list">
            <span class="title">${ result.title }</span>
            <span class="shaving">${ result.shaving }%</span>
            </a>
        </li>
        `;
        document.querySelector('#subID').value = ''
        document.querySelector('#shaving-percent').value = ''

    }
    console.log('submitted')
}

const AdminLayout = () => {
    console.log("window.location.pathname", window.location.pathname);

    const [userData, setUserData] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let id = fetchData("id");
                let response = await fetch(mongodbURL + "getuser-admin", {
                    method: "POST",
                    body: JSON.stringify({ id }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                let result = await response.json();
                setUserData(result); // Store result in state
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    // Redirect non-admin users
    useEffect(() => {
        if (userData && userData.admin !== "true") {
            navigate("/dashboard"); // Redirect to /dashboard
        }
    }, [userData, navigate]);

    return (
        <>
            {userData?.admin === "true" && (
                <>
                <div className="layout">
                    <main id="main-dashboard">
                        <Outlet />
                    </main>
                </div>
                <div id="modal-pop-bg" className="modal-bg">
                    <div id="modal-add-assets" className="modal-content">
                        <a href="/" className="modal-close" onClick={ ModalClose }><IconCloseBlue /></a>
                        <div className="wrap">
                            <h3>Add New Postback URL Shaving</h3>
                            <div className="add-assets-main-blocks">
                                <div className="add-asset-container add-asset-email-container">
                                    <Form method="post" className="form-block" onSubmit={ formSubmit }>
                                        <h4>subID / shaving %</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="subID" name="subID" placeholder="subID" required/>
                                            </div>
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="shaving-percent" name="shaving-percent" placeholder="Shaving %" required onInput={ (e) => e.target.value = e.target.value.replace(/[^0-9]/g, '') } />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Shaving</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
};

export default AdminLayout;
