
import { useEffect, useState } from "react";
import userProfilePic from "../assets/images/user-profile-pic.png"
import { addAssets, customSelectWrap, fetchData, mongodbURL, setUserTo, showNotifications } from "../helper";

const DashboardPageTitle = ({ title, show, extraclasses }) =>{

    const [saAllUsers, saGetAllUsers] = useState();

    const dashboardFirstload = async() => {
        let id = fetchData('id')
        let result = await fetch(
        mongodbURL + 'getuser-admin', {
            method: "post",
            body: JSON.stringify({ id }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();
        //console.log(id)
        console.log('dashboardFirstload', result)
/*
        console.log(window.location.pathname)

        if( result.status ==='account-cancelled' && window.location.pathname !== '/dashboard/account/renew'){
            window.location.href = '/dashboard/account/renew'
        }
*/
        if( document.querySelector('#account-setting-main-page') ){
            document.querySelector('#email').value = result.email
            document.querySelector('#username').value = result.username
            document.querySelector('#firstname').value = result.firstname
            document.querySelector('#lastname').value = result.lastname
            document.querySelector('#role').value = result.role
        }

        if( typeof result.allowNotifications !== 'undefined' && result.allowNotifications === 'enabled'){
            document.querySelectorAll('[data-allow-notification-container]').forEach(el => {
                el.removeAttribute('style')
            });
        } else {
            document.querySelectorAll('[data-allow-notification-container]').forEach(el => {
                el.style.display = 'none'
            });
        }

        if( document.querySelector('#checkbox-2fa') ){
            if( typeof result.twoFactor !== 'undefined' && result.twoFactor === 'enabled'){
                document.getElementById("checkbox-2fa").checked = true;
            } else{
                document.getElementById("checkbox-2fa").checked = false;
            }

            if( typeof result.allowNotifications !== 'undefined' && result.allowNotifications === 'enabled'){
                document.getElementById("settings-allow-notification").checked = true;
            } else{
                document.getElementById("settings-allow-notification").checked = false;
            }

            if( typeof result.allowEmails !== 'undefined' && result.allowEmails === 'enabled'){
                document.getElementById("settings-allow-email").checked = true;
            } else{
                document.getElementById("settings-allow-email").checked = false;
            }

            if( typeof result.allowMonthlyReporting !== 'undefined' && result.allowMonthlyReporting === 'enabled'){
                document.getElementById("settings-allow-monthlyreporting").checked = true;
            } else{
                document.getElementById("settings-allow-monthlyreporting").checked = false;
            }

            if( typeof result.companyName !== 'undefined' ){
                document.getElementById("company-name").value = result.companyName;
            }

            if( typeof result.companyDomain !== 'undefined' ){
                document.getElementById("company-domain").value = result.companyDomain;
            }
        }

        if( document.querySelector('#ca_subscription_id') ){
            let recurlyId = result.recurlyInvoice
            if( typeof recurlyId !== 'undefined' ){
                document.querySelector('#ca_subscription_id').value = recurlyId.subscriptionIDs[0]
                document.querySelectorAll('[data-paymentmethod="paypal"]').forEach(el => {
                    el.style.display = 'none'
                });
            } else {
                document.querySelectorAll('[data-paymentmethod="recurly"]').forEach(el => {
                    el.style.display = 'none'
                });
            }
        }

        if( document.querySelector('#monitored-assets') ){
            let monitoredAssets = result.monitoredAssets;

            if( typeof monitoredAssets === 'undefined' ){
                document.querySelector('#monitored-assets').innerText = '0/10';
                document.querySelector('#monitored-assets-score').setAttribute('data-score', 0)
            } else{
                document.querySelector('#monitored-assets').innerText = monitoredAssets.length + '/10';
                document.querySelector('#monitored-assets-score').setAttribute('data-score', monitoredAssets.length)
            }
        }

        let productType = result.productType;

        if( typeof productType === 'undefined' ){
            document.querySelectorAll('[data-plan-type]').forEach( el => {
                el.innerText = 'Dark Web Monitoring Monthly'
            });
        } else{
            if( productType === 'trial'){
                document.querySelectorAll('[data-plan-type]').forEach( el => {
                el.innerText = 'Dark Web Monitoring Trial'
                });
                document.querySelectorAll('[data-show-change-plan]').forEach( el => {
                    el.removeAttribute('style')
                });
            }
            if( productType === 1 || productType === 2){
                document.querySelectorAll('[data-plan-type]').forEach( el => {
                el.innerText = 'Dark Web Monitoring Monthly'
                });
                document.querySelectorAll('[data-show-change-plan]').forEach( el => {
                    el.removeAttribute('style')
                });
            }
            if( productType === 3){
                document.querySelectorAll('[data-plan-type]').forEach( el => {
                el.innerText = 'Dark Web Monitoring Yearly'
                });
            }
        }
        
        if( result && result.superadmin ){
            let rEmail = result.email
            let resultAllUsers = await fetch(
                mongodbURL + 'superadmin-get-all-users', {
                    method: "post",
                    body: JSON.stringify({ rEmail }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            resultAllUsers = await resultAllUsers.json();
            
            let tempSAAllUsesrs = resultAllUsers.map(
                (item) => {
                    return(
                        <>
                       <div onClick={ setUserTo }className='custom-select-item' data-value={ item.email }>{ item.firstname } { item.lastname } <span className="small">({ item.email.replace(/-/g, '‑') })</span></div>
                        </>
                    )
                }
            );
            saGetAllUsers(tempSAAllUsesrs)
        }
    }

    useEffect(() => {
        dashboardFirstload();
    }, []);

    extraclasses = 'd-block page-title-block  flex-wrap flex-align-center flex-justify-between ' + extraclasses
    return (
        <>
        <div className={ extraclasses }>
            <h2 className={ show }>{ title }<a href="/" id="add-new-asset-btn" onClick={ addAssets }>+  Add Asset</a></h2>
            <div className="d-meta-links flex-wrap flex-align-center">
                <ul className="flex-wrap flex-align-center">
                    <li><a href="/dashboard/help">Support</a></li>
                    <li data-allow-notification-container style={{ 'display': 'none' }}>
                        <a href="/" className="bell" onClick={ showNotifications }>
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.75 0C10.4141 0 11 0.585938 11 1.25V1.95312C13.8125 2.42188 16 4.88281 16 7.8125V9.14062C16 10.8984 16.5859 12.6172 17.6797 14.0234L18.2656 14.7266C18.5 15.0391 18.5391 15.4297 18.3828 15.7422C18.2266 16.0547 17.9141 16.25 17.5625 16.25H1.9375C1.54688 16.25 1.23438 16.0547 1.07812 15.7422C0.921875 15.4297 0.960938 15.0391 1.19531 14.7266L1.78125 14.0234C2.875 12.6172 3.5 10.8984 3.5 9.14062V7.8125C3.5 4.88281 5.64844 2.42188 8.5 1.95312V1.25C8.5 0.585938 9.04688 0 9.75 0ZM9.75 3.75H9.4375C7.17188 3.75 5.375 5.58594 5.375 7.8125V9.14062C5.375 11.0156 4.82812 12.8125 3.8125 14.375H15.6484C14.6328 12.8125 14.125 11.0156 14.125 9.14062V7.8125C14.125 5.58594 12.2891 3.75 10.0625 3.75H9.75ZM12.25 17.5C12.25 18.1641 11.9766 18.8281 11.5078 19.2969C11.0391 19.7656 10.4141 20 9.75 20C9.08594 20 8.42188 19.7656 7.95312 19.2969C7.48438 18.8281 7.25 18.1641 7.25 17.5H9.75H12.25Z" fill="black"/>
                            </svg>
                        </a>
                    </li>
                    <li className="user-img">
                        <a href="/dashboard/account">
                            <img className="user-image-src" src={ userProfilePic } alt="" />
                        </a>
                    </li>
                    { saAllUsers &&
                    <>
                        <div className="flex-wrap flex-wrap-block flex-align-center">
                            <span className="logged-in-as">Logged as :</span>
                            
                            <div className="d-block-dropdown style-1">
                                <div className='custom-select-dropdown-wrap'>
                                    <div id="value-select-sa-1" className='value-selected' onClick={ customSelectWrap }>{ fetchData('userName') }</div>
                                        <div className='custom-select-dropdown' data-value-seletected-id="value-select-sa-1">
                                        { saAllUsers }
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </>
                    }
                </ul>
            </div>
        </div>
        </>
    );
}
export default DashboardPageTitle