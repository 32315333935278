import { Form, Link, useActionData } from "react-router-dom";
import logo from "../logo.png";
import safecureimage1 from "../assets/images/safe-secure-1.png";
import safecureimage2 from "../assets/images/safe-secure-2.png";
import safecureimage3 from "../assets/images/safe-secure-3.png";
import safecureimage4 from "../assets/images/safe-secure-payments.png";
import { mongodbURL } from "../helper";
import { useEffect } from "react";

//action
export async function loginAction({ request }){
    const data = await request.formData();
    const formData = Object.fromEntries(data)

    const formUserName = formData.userName
    const formPassword = formData.password

    let result = await fetch(
    mongodbURL + 'login', {
        method: "post",
        body: JSON.stringify({ formUserName, formPassword }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    console.warn(result);
    if( result === null ) return { error:'Invalid Username/Password'};

    console.log("formData : ", formData);
    
    if( result.login_status === 'success' ){
        localStorage.setItem("userName", formData.userName)
        localStorage.setItem("id", result._id)
        localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
        localStorage.setItem('userStatus', result.status )
        localStorage.setItem('account-last-scanned', result.datemodified )
        if( result.image ){
            localStorage.setItem('profile-image', '/uploaded/' + result.image )
        } else {
            localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
        }

        console.log('redirect to dashboard');
        window.location.href = '/dashboard';
        /**/
        return { success:'successful'};
    } else if( result.login_status === 'first-login-required' ){
        localStorage.setItem("userName", formData.userName)
        localStorage.setItem("id", result._id)
        localStorage.setItem('userFullname', result.firstname + ' ' + result.lastname )
        localStorage.setItem('userStatus', result.status )
        localStorage.setItem('account-last-scanned', result.datemodified )
        if( result.image ){
            localStorage.setItem('profile-image', '/uploaded/' + result.image )
        } else {
            localStorage.setItem('profile-image', '/uploaded/imageplaceholder.jpg' )
        }
        window.location.href = '/dashboard';
        /**/
        return { success:'successful'};
    }
    else {
        return { error:'Invalid Username/Password'};
    }
    //return redirect('/dashboard');
}

const Login = () =>{
    const data = useActionData()

    useEffect(() => {
        document.title = "Login - Protect Your Identity with CyberAlerts";
    }, [] );
    
    return (
        <>
        <div id="page-container" className="login-page">
            <div className="login-container">
                <div className="logo-block"><Link to="/" className="logo"><img loading="lazy" src={logo} alt="" className="img-as-block" /></Link></div>
                <div className="logo-form-wrapper">
                    <h3 className="text-center">Log in to your account</h3>
                    <Form method="post" className="form-block">
                        { data && data.error && <p>{data.error}</p>}
                        <div className="form-field">
                            <div className="form-field-label">
                                <label htmlFor="username">Email Address</label>
                            </div>
                            <input type="email" id="username" name="userName" className="input-text" tabIndex={1} placeholder="Enter email address"  />
                        </div>
                        <div className="form-field">
                            <div className="form-field-label flex-wrap flex-justify-between">
                                <label htmlFor="password">Password</label>
                                <a href="/forgotpassword" className="forgot-password">Forgot Password</a>
                            </div>
                            <input type="password" id="password" name="password" className="input-text" tabIndex={2} placeholder="Password"  />
                        </div>
                        <div className="form-field submit">
                            <button className="btn blue-btn full-btn" tabIndex={3} type="submit">
                                <span>Sign in</span>
                            </button>
                        </div>
                        
                        <div className="form-field text-center dont-have-account">
                            Don’t have an account? <a href="/pricing">Sign Up</a>
                        </div>
                    </Form>
                </div>
                <div className="secure-images" style={{ display : 'none' }}>
                    <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ safecureimage1 } alt="" className="img-as-block"/>
                        <img loading="lazy" src={ safecureimage2 } alt="" className="img-as-block"/>
                        <img loading="lazy" src={ safecureimage3 } alt="" className="img-as-block"/>
                    </div>
                    <div className="flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ safecureimage4 } alt="" className="img-as-block"/>
                    </div>
                </div>
                <div className="text-center login-copyright">
                    <div>This site is protected by reCAPTCHA and the Google <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms of Service</a> apply.</div>
                    <div className="login-copyright-text">Copyright &copy; {(new Date().getFullYear())} CyberAlerts Inc. All Rights Reserved.</div>
                </div>
            </div>
        </div>
		</>
    );
}
export default Login