import { useEffect } from "react";
import BannerHomepage2 from "../sections/BannerHomepage2";
import BannerIdentityTheft from "../sections/BannerIdentityTheft";
import FAQBlock from "../sections/FAQBlock";
import Footer from "../sections/Footer";
import ImageWithTextServicePage from "../sections/ImageWithTextServicePage";
import SliderIconBoxes from "../sections/SliderIconBoxes";
import ThreeColumnsServicePage from "../sections/ThreeColumnsServicePage";

const PageIdentityTheft = () =>{

    useEffect(() => {
        document.title = "Identity Theft Protection - Protect Your Identity with CyberAlerts";
    }, [] );
    
    return (
        <>
            <BannerIdentityTheft />
            <ImageWithTextServicePage />
            <ThreeColumnsServicePage />
            <SliderIconBoxes />
            <FAQBlock classes='section flexbox flexbox-spacing-2 faq-blocks radial3-bg lazyload-bg' />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default PageIdentityTheft