import SecureImage1 from '../assets/images/safe-secure-1.png';
import SecureImage2 from '../assets/images/safe-secure-2.png';
import SecureImage3 from '../assets/images/safe-secure-3.png';
import SecureImage4 from '../assets/images/safe-secure-payments.png';
import { showNextSibling } from '../helper';

const PlansAndPricing = ({showButton}) =>{
    return (
        <>
        <section className="section flexbox price-table radial-bg lazyload-bg">
            <div className="wrapper">
                <div className="content-block text-center full-width">
                    <div className="intro">Plans & Pricing</div>
                    <h2 className="small-wrapper-auto">All-in-One Managed Cyber Security Subscriptions.</h2>
                    <p className='small-wrapper-auto med'>A simple way to start protecting yourself and your family from emerging cyber threats.</p>
                </div>
                <div className="price-table-section">
                    <div className="price-table-block" style={ { display: 'none' }}>
                        <div className="price-table-title">24 HOUR TRIAL</div>
                        <div className="price-table-price"><span className="price">$1.00</span><span className="month text-blue" style={{ display: 'none'}}>Then $19.99/mo</span></div>
                        <div className="price-table-content">
                            <p className="med text-black">Round-the-clock availability, technical support & monitoring</p>
                        </div>
                        <div className="price-table-btn">
                            <a href="/checkout?product=1" className="btn blue-btn">
                                <span>Start 24 Hour Trial</span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                        
                        <div className="price-table-list">
                            <ul>
                                <li>
                                   <div className="accord-item no-click">5 Family Members</div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">Monitor Domains</div>
                                </li>
                                <li>
                                    <div className="accord-item" onClick={ showNextSibling }>Family &amp; Child Identity Protection</div>
                                    <div className="accord-content hide-element">
                                        <h6>Parental Controls</h6>
                                        <p>Let your kids browse online safely. Filter harmful sites and easily manage how much time they spend on apps like YouTube, Roblox, SnapChat and more. Powered by Circle, an award-winning technology used by thousands of parents.</p>
                                        <h6>Safe Gaming with Cyberbullying Alerts</h6>
                                        <p>Keep your child safe from online predators, scams, and cyberbullying when playing online video games. Get alerted to threats with 24/7 in-game voice and text monitoring for over 200 of the most popular PC games. Powered by award-winning ProtectMe by Kidas.</p>

                                        <h6>Family Fraud Alerts Sharing</h6>
                                        <p>Aura makes it easy to request and share identity and credit threat alerts among your family, so you can stay on top of everyone’s online safety. Works exclusively on Aura Family plan.</p>

                                        <h6>Child SSN Monitoring</h6>
                                        <p>Nearly 1M children were victims of identity theft in 2022. Aura scans billions of public records to detect and alert you if your Child’s SSN is being used by someone else. </p>

                                        <h6>Child 3-Bureau Credit Freeze</h6>
                                        <p>If you are a parent or guardian, you can protect your child’s credit by requesting the credit bureaus to freeze their credit file. This process is tedious and involves a ton of paperwork. Aura has streamlined the process to make it easy for you to submit your child’s credit freeze request across Equifax, Experian, and Transunion.</p>

                                        <h6>Child Sex Offender Geo-Alerts</h6>
                                        <p>Aura automatically alerts you to known and new sex offenders within a 1-mile radius of your provided address so you’re more empowered to keep your family safe.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">$5M Identity Theft Insurance*</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">3-Bureau Credit Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Instant Credit Lock</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Home &amp; Auto Title Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Financial Transaction Alerts</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Antivirus, VPN, Password Manager</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">People Search Sites &amp; Spam Lists Removal</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Parental Controls</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Safe Gaming</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">AI Spam Call &amp; Message Protection</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">24/7 Support &amp; Expert Fraud Remediation</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="price-table-block">
                        <div className="price-table-title">Monthly</div>
                        <div className="price-table-price"><span className="price">$19.99</span><span className="month">/month</span></div>
                        <div className="price-table-content">
                            <p className="med text-black">Round-the-clock availability, technical support & monitoring</p>
                        </div>
                        <div className="price-table-btn">
                            <a href="/checkout?product=2" className="btn blue-btn">
                                <span>Select</span>
                            </a>
                        </div>
                        
                        
                        <div className="price-table-list">
                        <ul>
                                <li>
                                   <div className="accord-item no-click">5 Family Members</div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">Monitor Domains</div>
                                </li>
                                <li>
                                    <div className="accord-item" onClick={ showNextSibling }>Family &amp; Child Identity Protection</div>
                                    <div className="accord-content hide-element">
                                        <h6>Parental Controls</h6>
                                        <p>Let your kids browse online safely. Filter harmful sites and easily manage how much time they spend on apps like YouTube, Roblox, SnapChat and more. Powered by Circle, an award-winning technology used by thousands of parents.</p>
                                        <h6>Safe Gaming with Cyberbullying Alerts</h6>
                                        <p>Keep your child safe from online predators, scams, and cyberbullying when playing online video games. Get alerted to threats with 24/7 in-game voice and text monitoring for over 200 of the most popular PC games. Powered by award-winning ProtectMe by Kidas.</p>

                                        <h6>Family Fraud Alerts Sharing</h6>
                                        <p>Aura makes it easy to request and share identity and credit threat alerts among your family, so you can stay on top of everyone’s online safety. Works exclusively on Aura Family plan.</p>

                                        <h6>Child SSN Monitoring</h6>
                                        <p>Nearly 1M children were victims of identity theft in 2022. Aura scans billions of public records to detect and alert you if your Child’s SSN is being used by someone else. </p>

                                        <h6>Child 3-Bureau Credit Freeze</h6>
                                        <p>If you are a parent or guardian, you can protect your child’s credit by requesting the credit bureaus to freeze their credit file. This process is tedious and involves a ton of paperwork. Aura has streamlined the process to make it easy for you to submit your child’s credit freeze request across Equifax, Experian, and Transunion.</p>

                                        <h6>Child Sex Offender Geo-Alerts</h6>
                                        <p>Aura automatically alerts you to known and new sex offenders within a 1-mile radius of your provided address so you’re more empowered to keep your family safe.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">$5M Identity Theft Insurance*</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">3-Bureau Credit Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Instant Credit Lock</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Home &amp; Auto Title Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Financial Transaction Alerts</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Antivirus, VPN, Password Manager</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">People Search Sites &amp; Spam Lists Removal</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Parental Controls</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Safe Gaming</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">AI Spam Call &amp; Message Protection</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">24/7 Support &amp; Expert Fraud Remediation</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="price-table-block">
                        <div className="price-table-title">Annual</div>
                        <div className="price-table-price"><span className="price">$179.91</span><span className="month">/year</span></div>
                        <div className="price-table-content">
                            <p className="med text-black">Protect yourself and your family from vulnerabilities</p>
                        </div>
                        <div className="price-table-btn">
                            <a href="/checkout?product=3" className="btn blue-btn">
                                <span>Select</span>
                            </a>
                        </div>
                        
                        <div className="price-table-list">
                            <ul>
                                <li>
                                   <div className="accord-item no-click">5 Family Members</div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">Monitor Domains</div>
                                </li>
                            <li>
                                    <div className="accord-item" onClick={ showNextSibling }>Family &amp; Child Identity Protection</div>
                                    <div className="accord-content hide-element">
                                        <h6>Parental Controls</h6>
                                        <p>Let your kids browse online safely. Filter harmful sites and easily manage how much time they spend on apps like YouTube, Roblox, SnapChat and more. Powered by Circle, an award-winning technology used by thousands of parents.</p>
                                        <h6>Safe Gaming with Cyberbullying Alerts</h6>
                                        <p>Keep your child safe from online predators, scams, and cyberbullying when playing online video games. Get alerted to threats with 24/7 in-game voice and text monitoring for over 200 of the most popular PC games. Powered by award-winning ProtectMe by Kidas.</p>

                                        <h6>Family Fraud Alerts Sharing</h6>
                                        <p>Aura makes it easy to request and share identity and credit threat alerts among your family, so you can stay on top of everyone’s online safety. Works exclusively on Aura Family plan.</p>

                                        <h6>Child SSN Monitoring</h6>
                                        <p>Nearly 1M children were victims of identity theft in 2022. Aura scans billions of public records to detect and alert you if your Child’s SSN is being used by someone else. </p>

                                        <h6>Child 3-Bureau Credit Freeze</h6>
                                        <p>If you are a parent or guardian, you can protect your child’s credit by requesting the credit bureaus to freeze their credit file. This process is tedious and involves a ton of paperwork. Aura has streamlined the process to make it easy for you to submit your child’s credit freeze request across Equifax, Experian, and Transunion.</p>

                                        <h6>Child Sex Offender Geo-Alerts</h6>
                                        <p>Aura automatically alerts you to known and new sex offenders within a 1-mile radius of your provided address so you’re more empowered to keep your family safe.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accord-item no-click">$5M Identity Theft Insurance*</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">3-Bureau Credit Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Instant Credit Lock</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Home &amp; Auto Title Monitoring</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Financial Transaction Alerts</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Antivirus, VPN, Password Manager</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">People Search Sites &amp; Spam Lists Removal</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Parental Controls</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">Safe Gaming</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">AI Spam Call &amp; Message Protection</div>
                                </li>
                                <li>
                                <div className="accord-item no-click">24/7 Support &amp; Expert Fraud Remediation</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="secure-images">
                    <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ SecureImage4 } alt="" className="img-as-block" />
                    </div>
                    <div className=" flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ SecureImage1 } alt="" className="img-as-block" />
                        <img loading="lazy" src={ SecureImage2 } alt="" className="img-as-block" />
                        <img loading="lazy" src={ SecureImage3 } alt="" className="img-as-block" />
                    </div>
                </div>
                <div className="safe-secure-content text-center">
                    <p>* The introductory purchase price today is valid for the first term of your new subscription. Your subscription renews each year at the then-current subscription renewal price. Pricing is subject to change.</p>
                </div>
            </div>
        </section>
        </>
    );
}
export default PlansAndPricing