import ButtonCheckout from "./ButtonCheckout";
import gridIcon2 from '../assets/images/grid-icon-2.png';
import gridIcon3 from '../assets/images/grid-icon-3.png';
import gridIcon4 from '../assets/images/grid-icon-4.png';
import ButtonGetInTouch from "./ButtonGetInTouch";

const GridIconBox1 = () =>{
    return (
        <>
        <section id="section-3" className="has-border-top flexbox section">
            <div className="wrapper">
                <div className="flex-wrap col-gap-30 content-right">
                    <div className="one-half">
                        <div className="content-block width-2">
                            <div className="intro">Products & Solutions</div>
                            <h2>Gain complete visibility of your personal security posture continuously.</h2>
                            <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower you with the tools and information needed to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals and their families.</p>
                            <div className="btn-flex-holder">
                                <ButtonCheckout />
                                <ButtonGetInTouch />
                            </div>
                        </div>
                    </div>
                    <div className="one-half">
                        <div className="grid-icon-boxes">
                            <div className="grid-icon-box">
                                <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                                <h3>Identity Theft</h3>
                                <p>As already mentioned, Identity theft accounts for over $6 billion in damages every year. This underscores the importance of the product.</p>
                                <div className="learn-more">
                                    <a href="/">Learn more
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="grid-icon-box">
                                <div className="icon"><img loading="lazy" src={ gridIcon3 } alt="" className="img-as-block" /></div>
                                <h3>Real-time Monitoring</h3>
                                <p>Our tool scans over 10 million dark web pages daily for leaked personal data.</p>
                                <div className="learn-more">
                                    <a href="/">Learn more 
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="grid-icon-box">
                                <div className="icon"><img loading="lazy" src={ gridIcon4 } alt="" className="img-as-block" /></div>
                                <h3>User Metrics</h3>
                                <p>Over 99,632 satisfied users protected daily with our tool.</p>
                                <div className="learn-more">
                                    <a href="/">Learn more 
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default GridIconBox1