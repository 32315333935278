
export async function webhookLiveMonitoringAction({ request }){
    const response = await fetch('https://api.enzoic.com/v1/webhooks', {
        method: 'POST',
        headers: {
          "Authorization": "Basic Y2M3ZDBhMTQ3YzVhNDk1M2FmZGE1NWNmOGU2OGU5YWM6QSExbXZfMm0xTTR1Vzdzcncmajk2RSE3Z1ZDWGZUVXo=",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "url": "https://cyberalerts.net/api/webhook-live-monitoring"
        }),
    });
    const data = await response.json();
    console.log(data)
}


const WebhookLiveMonitoring = () =>{
    return (
        <>
        <div className="blocked-page">
            <h2 className="text-black">User Blocked</h2>
            <p className="text-black" onClick={ webhookLiveMonitoringAction }>Webhook</p>
        </div>
        </>
    );
}
export default WebhookLiveMonitoring