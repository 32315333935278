import { useState, useEffect } from "react";
import heroBanner from '../assets/images/hero-banner.jpg';

const ImageHeroBanner = () => {
  const [imageSrc, setImageSrc] = useState(""); // Initially empty

  useEffect(() => {
    const handleLoad = () => {
      setImageSrc(heroBanner); // Set image after page load
    };

    if (document.readyState === "complete") {
      handleLoad(); // If page is already loaded, set immediately
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <img 
      src={imageSrc} 
      loading="eager" 
      fetchpriority="high" 
      alt="Hero Banner" 
      className="img-as-block"
    />
  );
};

export default ImageHeroBanner;