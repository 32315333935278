
import { useEffect, useState } from "react";
import AdminDashboardHeader from "./AdminDashboardHeader";
import AdminDashboardPageTitle from "./AdminDashboardTitle";
import { mongodbURL } from "../helper";
import { useNavigate, useParams } from "react-router-dom";


const AdminPostBackURLShavingEdit = () => {
    const [subIDTitle, setSubIDTitle] = useState("");
    const [shavingValue, setShavingValue] = useState("");
    const [actionresponse, setactionresponse] = useState("");

    
    const navigate = useNavigate(); 
    let { id } = useParams(); // Destructure ID

    const handleDelete = async () => {
        let response = await fetch(mongodbURL + "postback-track-delete-single", {
            method: "POST",
            body: JSON.stringify({ id }),
            headers: { "Content-Type": "application/json" },
        });

        let result = await response.json();
        if(result){
            navigate("/admin/postback-shaving?removed=1");
        }
    }

    const handleSave = async () => {
        let subID = document.querySelector('#subID').value
        let shavingPercent = document.querySelector('#shaving-percent').value

        let response = await fetch(mongodbURL + "postback-track-save-single", {
            method: "POST",
            body: JSON.stringify({ id, subID, shavingPercent }),
            headers: { "Content-Type": "application/json" },
        });

        let result = await response.json();
        if(result){
            setactionresponse(result.message)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (!id) return; // Ensure ID is available

            try {
                let response = await fetch(mongodbURL + "postback-track-get-single", {
                    method: "POST",
                    body: JSON.stringify({ id }),
                    headers: { "Content-Type": "application/json" },
                });

                let result = await response.json();
                console.log(result);

                setSubIDTitle(result.postback.subid || ""); // Default to empty string
                setShavingValue(result.postback.shaving || ""); // Default to empty string
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [id]); // Depend on ID only

    return (
        <>
            <AdminDashboardHeader active="postback-shaving" />
            <div className="d-wrapper">
                <div className="admin-header">
                    <AdminDashboardPageTitle title="Postback URL Shaving" />
                    <a href="/admin/postback-shaving/" id="add-new-asset-btn" className="button btn blue-btn small">Back</a>
                </div>
                <div className="d-block email-report-page layout-2">
                    <div className="wrap position-relative">
                        <div className="asset-placeholder-response mb-30" dangerouslySetInnerHTML={{ __html: actionresponse }} />
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-whole">
                                <label htmlFor="subID">SubID</label>
                                <input
                                    type="text"
                                    className="input-text"
                                    id="subID"
                                    name="subID"
                                    placeholder="subID"
                                    required
                                    value={subIDTitle}
                                    onChange={(e) => setSubIDTitle(e.target.value)} // ✅ Fix: Allow editing
                                />
                            </div>
                        </div>
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-whole">
                                <label htmlFor="shaving-percent">Shaving %</label>
                                <input
                                    type="number" // ✅ Allows only numbers
                                    className="input-text"
                                    id="shaving-percent"
                                    name="shaving-percent"
                                    placeholder="Shaving %"
                                    required
                                    value={shavingValue}
                                    onChange={(e) => {
                                        let value = e.target.value.replace(/\D/, ""); // Remove non-numeric characters
                                        if (value > 100) value = 100; // Ensure value does not exceed 100
                                        setShavingValue(value);
                                    }}
                                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} // ✅ Blocks unwanted characters
                                    min="1"
                                    inputMode="numeric" // ✅ Helps mobile users
                                />
                            </div>
                        </div>
                        <button className="button btn blue-btn" onClick={ handleSave }>Save</button> <button className="button btn red-btn" onClick={() => {
                            if (window.confirm("Are you sure you want to delete this?")) {
                                handleDelete(); // Call delete function
                            }
                        }}>Delete</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminPostBackURLShavingEdit;
