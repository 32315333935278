import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { customSelectWrap, fetchData, mongodbURL } from '../helper';
/*
function Last7Days () {
  return '0123456'.split('').map(function(n) {
      var d = new Date();
      d.setDate(d.getDate() - n);
      return (function(day, month, year) {
        return [day<10 ? '0'+day : day, month<10 ? '0'+month : month, year].join('/');
      })(d.getDate(), d.getMonth() + 1, d.getFullYear());
      
  });
}

let days7 = Last7Days();
*/

const data = []

if( fetchData('userName') ){

let setupData = async() => {


  let email = fetchData('userName')
  let result = await fetch(
  mongodbURL + 'get-user-riskactivities-7-days', {
      method: "post",
      body: JSON.stringify({ email }),
      headers: {
          'Content-Type': 'application/json'
      }
  })
  result = await result.json();
  return result;
}
let getRiskData = []

setupData().then( result => {
  getRiskData = result;
  let totalRisk = 0;

  if( document.querySelector('#recharts-container') ){
    console.log('setup dashboard risk activity')
    for (let i = 0; i < getRiskData.length; i++) {
      if( i < 7 ){
      var dict = {}
      dict['name'] = getRiskData[i].date
      dict['date'] = getRiskData[i].date
      dict['day'] = getRiskData[i].day
      dict['risk'] = getRiskData[i].riskcounts
      dict['Issues Resolved'] = '0'
      dict['Risk Records'] = getRiskData[i].riskcounts
      data[i] = dict
      totalRisk = totalRisk + getRiskData[i].riskcounts
      }
    }

  
    document.querySelector('#recharts-container').style.width = "100%"
    document.querySelector('#risk-activity-container').classList.add('loaded')
    document.querySelector('#risks-total-large').innerText = totalRisk
  }
})

}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ 'borderRadius' : '4px', "background" : "#fff", 'padding' : '10px', 'border' : '1px solid #D9D9D9', 'boxShadow' : '0px 0px 5px 0 rgba(0, 0, 0, 0.2);' }}>
        <p className="date">{`${ new Date(payload[0].payload['date']).toDateString().split(' ').slice(1).join(' ') }`}</p>
        <p className="label-1" style={{ color : `${payload[0].fill}` }}>{`${payload[0].dataKey} : ${payload[0].payload[payload[0].dataKey]}`}</p>
        <p className="label-2">{`${payload[1].dataKey} : ${payload[1].payload[payload[1].dataKey]}`}</p>
      </div>
    );
  }

  return null;
};


const ChangeValue = (e) => {
  e.target.closest('.custom-select-dropdown-wrap').classList.remove('active')
  document.querySelector('#' + e.target.parentNode.getAttribute('data-value-seletected-id') ).innerText = e.target.innerText

  console.log( 'data-value', e.target.getAttribute('data-value') )
  
  document.querySelector('#recharts-container').style.width = "1%"
  document.querySelector('#recharts-container').style.width = "99%"
}


/*
const data = [
  {
    name: 'Jan',
    "Issues Resolved": 0,
    "Risk Records": 5
  },
  {
    name: 'Feb',
    "Issues Resolved": 0,
    "Risk Records": 5
  },
  {
    name: 'Mar',
    "Issues Resolved": 0,
    "Risk Records": 2
  },
  {
    name: 'Apr',
    "Issues Resolved": 5,
    "Risk Records": 2
  },
  {
    name: 'May',
    "Issues Resolved": 5,
    "Risk Records": 2
  },
  {
    name: 'Jun',
    "Issues Resolved": 5,
    "Risk Records": 2
  }
];
*/


export default class DashboardSectionRiskActivity extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      datad : data
    }
  }

  async setToLastHour(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-last-hour-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Last Hour'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  setTo7Days(){

    console.log(data)
    
    document.querySelector('#risk-activity-container').classList.remove('loaded')
    this.setState({ datad : data })

    let total = 0
    for(let i = 0; i < data.length; i++ ){
      total += data[i].risk
    }
    document.querySelector('#risks-total-large').innerText = total

    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Last 7 Days'
    document.querySelector('#risk-activity-container').classList.add('loaded')


  }

  async setToToday(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-today-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Today'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  async setToYesterday(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-yesterday-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Yesterday'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  async setToThisMonth(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-this-month-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'This Month'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  async setToLastWeek(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-last-week-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Last Week'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  async setTo30Days(){

    document.querySelector('#risk-activity-container').classList.remove('loaded')

    let email = fetchData('userName')
    let result = await fetch(
    mongodbURL + 'get-last-30-days-risk-activity-dropdown', {
        method: "post",
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();

    if( result ){
      console.log(result)
      let total = 0
      for(let i = 0; i < result.length; i++ ){
        total += result[i].risk
      }
      document.querySelector('#risks-total-large').innerText = total
      this.setState({ datad : result })
    }
    document.querySelector('#custom-select-dropdown-wrap1').classList.remove('active')
    document.querySelector('#value-select-1' ).innerText = 'Last 30 Days'
    document.querySelector('#risk-activity-container').classList.add('loaded')
  }

  render() {
    return (
        <>

        <div className="d-block score-graph-5 layout-2">
            <h2>Risk Activity</h2>
            <div className="wrap position-relative" id="risk-activity-container">
                <div className="loader-spinner">
                  <span className="loader big"></span>
                </div>
                <div className="risks-counts flex-wrap flex-justify-between flex-align-center">
                    <div className="flex-wrap risks-counts-labels">
                        <div className="risks-total-block resolve-block">
                            <span className="risks-total-large">0</span>
                            <div>Issues Resolved</div>
                        </div>
                        <div className="risks-total-block risk-records-block">
                            <span className="risks-total-large" id="risks-total-large"></span>
                            <div>Risk Records</div>
                        </div>
                    </div>
                    <div className="risks-count-limits">
                        <div id="custom-select-dropdown-wrap1" className='custom-select-dropdown-wrap'>
                          <select>
                              <option>Last hour</option>
                              <option>Today</option>
                              <option>Yesterday</option>
                              <option>Last 7 Days</option>
                              <option>Last Week</option>
                              <option>This Month</option>
                              <option>Last 30 Days</option>
                          </select>
                          <div id="value-select-1" className='value-selected' onClick={ customSelectWrap }>Last 7 Day</div>
                          <div className='custom-select-dropdown' data-value-seletected-id="value-select-1" data-location="dashboard-graph">
                            <div className='custom-select-item' onClick={ this.setToLastHour.bind(this) } data-value="last-hour">Last hour</div>
                            <div className='custom-select-item' onClick={ this.setToToday.bind(this) } data-value="today">Today</div>
                            <div className='custom-select-item' onClick={ this.setToYesterday.bind(this) } data-value="yesterday">Yesterday</div>
                            <div className='custom-select-item' onClick={ this.setTo7Days.bind(this) } data-value="last-7-days">Last 7 Days</div>
                            <div className='custom-select-item' onClick={ this.setToLastWeek.bind(this) } data-value="last-week">Last Week</div>
                            <div className='custom-select-item' onClick={ this.setToThisMonth.bind(this) } data-value="this-month">This Month</div>
                            <div className='custom-select-item' onClick={ this.setTo30Days.bind(this) } data-value="last-30-days">Last 30 Days</div>
                          </div>
                        </div>
                    </div>
                </div>
            <ResponsiveContainer id="recharts-container" width={'1%'} height={350}>
                <BarChart
                width={500}
                height={350}
                data={ this.state.datad }
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
                barSize={67}
                >
                <XAxis dataKey="day" scale="point" padding={{ left: 50, right: 50 }} axisLine={false} />
                <YAxis axisLine={false} tickLine={false} ticks={[0, 10, 20, 30, 40, 50, 60 ]} />
                <Tooltip  content={<CustomTooltip />} />
                <CartesianGrid 
                    vertical={false}
                    stroke="#E6EAF3"
                />
                <Bar dataKey="Issues Resolved" stackId="a" fill="#3D3ABB" />
                <Bar dataKey="Risk Records" stackId="a" fill="#CDD5EB" />
                </BarChart>
            </ResponsiveContainer>
            </div>
        </div>
      </>
    );
  }
}
