import { useEffect, useState } from "react";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { Form } from "react-router-dom";
import { fetchData, mongodbURL } from "../helper";


const WelcomePage = () =>{

    useEffect(() => {
    }, [] );

    async function handleSubmit () {

        let countError = 1;

        document.querySelectorAll('.dashboard-forms [required]').forEach((field) =>{
            
            if( field.checkValidity() === false ){
                field.closest('.form-field').classList.add('required')
                countError++;
            } else {
                if( field.value.length === 0 || field.closest('.form-field.email-already') ){
                    field.closest('.form-field').classList.add('required');
                    countError++;
                } else {
                    field.closest('.form-field').classList.remove('required')
                }
            }
        });

        if( countError === 1){
            formData.email = fetchData('userName');

            document.querySelector('#modal-pop-bg-save-settings').classList.toggle('fadeIn')
            document.querySelector('#modal-save-settings').classList.toggle('fadeIn')

            let result = await fetch(
            mongodbURL + 'save-data-first-login', {
                method: "post",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = await result.json();
            if( result ){
                setTimeout( function(){
                    document.querySelector('#modal-pop-bg-save-settings').classList.remove('fadeIn')
                    document.querySelector('#modal-save-settings').classList.remove('fadeIn')
                    document.querySelector('#responses').classList.add('show')
                }, 500)
                setTimeout( function(){
                    document.querySelector('#responses').classList.remove('show')
                }, 3000)
            }
        }
    }

    const [formData, setFormData] = useState({
        email : '',
        phone : ''
    });


    const onKeyPressInput = (e) => {
        if (e.charCode === 13) {
            alert('Enter... (KeyPress, use charCode)');
            document.querySelector('#checkout-next-step').click()
            e.preventDefault();
        }
        if (e.keyCode === 13) {
            document.querySelector('#checkout-next-step').click()
            e.preventDefault();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData, [name] : value
        })

        if( value ){
            e.target.closest('.form-field').classList.remove('required')
        }
    }

    return (
        <>
        <DashboardHeader />
        <div className="d-wrapper">
            <DashboardPageTitle title="Complete your Profile" />
            <div className="d-block classic-d-block dashboard-change-password layout-full-width">
                <div className="wrap">
                    <Form className="dashboard-forms the-checkout-block">
                        <div id="responses" className="response">Settings Updated.</div>
                        <h3>Complete your Profile</h3>
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>First Name</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="firstname"
                                    id="firstname"
                                    required
                                    data-recurly="first_name"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>Last Name</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="lastname"
                                    id="lastname"
                                    required
                                    data-recurly="last_name"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                        </div>
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-whole">
                                <div className="form-field-label">
                                    <label>Address Line 1</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="address1"
                                    id="address1"
                                    required
                                    data-recurly="address1"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            
                        </div>
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>City</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="city"
                                    id="city"
                                    required
                                    data-recurly="city"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>Country</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder="United States"
                                    name="country"
                                    id="country"
                                    required
                                    data-recurly="country"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            
                        </div>
                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field two-third">
                                <div className="form-field-label">
                                    <label>State</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="state"
                                    id="state"
                                    required
                                    data-recurly="state"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            <div className="form-field one-third">
                                <div className="form-field-label">
                                    <label>Zip Code</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder=""
                                    name="zipcode"
                                    id="zipcode"
                                    required
                                    data-recurly="postal_code"
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>

                        </div>

                        <div className="form-block flex-wrap flex-wrap-block">
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>SSN</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder="SSN Number"
                                    name="ssnnumber"
                                    id="ssnnumber"
                                    required
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                            <div className="form-field one-half">
                                <div className="form-field-label">
                                    <label>Date of Birth</label>
                                </div>
                                <input
                                    onKeyDown={ onKeyPressInput }
                                    onChange={ handleChange }
                                    type="text"
                                    className="input-text"
                                    placeholder="Date of Birth"
                                    name="dob"
                                    id="dob"
                                    required
                                />
                                <span className='error-text hide-element'>This field is required</span>
                            </div>
                        </div>

                    
                        <div className="form-field submit-field complete-profile-submit">
                            <div className="field flex-wrap flex-align-center flex-justify-center">
                                <input type="button" name="submit" id="submit" value="Save Changes"  onClick={ handleSubmit } />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        </>
    );
}
export default WelcomePage