import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import React, { useEffect, useState } from "react"
import { MyPaymentForm } from './recurly-payment-form';
import { fetchData } from '../helper';
import '../dashboard/assets/dashboard-responsive.css'
import { RECURLY_PUBLIC_API } from '../globals/Constants';

export async function CheckoutLoader(){
    

    /*
    let cancel = new URLSearchParams(window.location.search).get("cancel");
    console.log(cancel)
    if( cancel === 'true' ){

        console.log('its true')

        let subscription_id = new URLSearchParams(window.location.search).get("subscription_id");
        let ba_token = new URLSearchParams(window.location.search).get("ba_token");
        let token = new URLSearchParams(window.location.search).get("token");

        if( subscription_id && ba_token && token ){
            let result = await fetch(
            mongodbURL + 'update-user-account-details', {
                method: "post",
                body: JSON.stringify({ subscription_id : subscription_id, ba_token : ba_token, token : token }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = await result.json();

            console.log(result)
        }
    }
    */

    const userName = fetchData('userName')
    return { userName }
}


const CheckoutSignup = ({ classes }) =>{
    
    const [isRecurlyLoaded, setIsRecurlyLoaded] = useState(false);

  useEffect(() => {
    if (window.recurly) {
      setIsRecurlyLoaded(true); // Already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = "https://js.recurly.com/v4/recurly.js";
    script.async = true;
    script.onload = () => setIsRecurlyLoaded(true); // Wait for script to load

    document.body.appendChild(script);
  }, []);

  if (!isRecurlyLoaded) {
    return <></>
  }

    let cid = new URLSearchParams(window.location.search).get("cid");
    if( cid !== null){
        localStorage.setItem('cid', cid);
    }
    let subid = new URLSearchParams(window.location.search).get("subid");
    if( subid !== null){
        localStorage.setItem('subid', subid);
    }
    
    return (
        <>
        <RecurlyProvider publicKey={ RECURLY_PUBLIC_API }>
            <Elements>
                <MyPaymentForm />
            </Elements>
        </RecurlyProvider>
        </>
    )
}

export default CheckoutSignup