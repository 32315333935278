import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { fetchData, mongodbURL } from '../helper';

const data = []
if( fetchData('userName') ){

let setupDataa = async() => {


  let email = fetchData('userName')
  let result = await fetch(
  mongodbURL + 'get-user-riskactivities-7-days', {
      method: "post",
      body: JSON.stringify({ email }),
      headers: {
          'Content-Type': 'application/json'
      }
  })
  result = await result.json();
  
  return result;
}
let getRiskData = []

setupDataa().then( result => {
  getRiskData = result;
  let totalRisk = 0;

  if( document.querySelector('#recharts-containera') ){
  
  console.log('setup dashboard risk activity')

    for (let i = 0; i < getRiskData.length; i++) {
      if( i < 7 ){
        var dict = {}
        dict['name'] = getRiskData[i].day
        dict['Issues Resolved'] = '0'
        dict['Risk Records'] = getRiskData[i].riskcounts
        dict['date'] = getRiskData[i].date
        data[i] = dict
        totalRisk = totalRisk + getRiskData[i].riskcounts
      }
    }

    document.querySelector('#recharts-containera').style.width = "100%"
    document.querySelector('#risk-activity-container').classList.add('loaded')
    document.querySelector('#risks-total-large').innerText = totalRisk
  }
})

}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(label)
    console.log(payload)
    return (
      <div className="custom-tooltip" style={{ 'borderRadius' : '4px', "background" : "#fff", 'padding' : '10px', 'border' : '1px solid #D9D9D9', 'boxShadow' : '0px 0px 5px 0 rgba(0, 0, 0, 0.2);' }}>
        <p className="date">{`${ new Date(payload[0].payload['date']).toDateString().split(' ').slice(1).join(' ') }`}</p>
        <p className="label-1" style={{ color : `${payload[0].fill}` }}>{`${payload[0].dataKey} : ${payload[0].payload[payload[0].dataKey]}`}</p>
        <p className="label-2">{`${payload[1].dataKey} : ${payload[1].payload[payload[1].dataKey]}`}</p>
      </div>
    );
  }

  return null;
};

export default class DashboardSectionWeeklyActivity extends PureComponent {
  render() {
    return (
        <>

        <div className="wrap weekly-activity">
            <div className="d-block-header">
                <h3>Weekly Activity</h3>
            </div>
            <div className="position-relative" id="risk-activity-container">
                <div className="loader-spinner">
                  <span className="loader big"></span>
                </div>
            <div className="risks-counts flex-wrap flex-justify-between flex-align-center">
                <div className="flex-wrap risks-counts-labels">
                    <div className="risks-total-block resolve-block">
                        <span className="risks-total-large">0</span>
                        <div>Issues Resolved</div>
                    </div>
                    <div className="risks-total-block risk-records-block">
                        <span className="risks-total-large" id="risks-total-large">0</span>
                        <div>Risk Records</div>
                    </div>
                </div>
            </div>
            <ResponsiveContainer id="recharts-containera" width={'1%'} height={350}>
                <BarChart
                width={500}
                height={350}
                data={data}
                margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                }}
                >
                <XAxis dataKey="name" scale="point" padding={{ left: 5, right: 5 }} axisLine={false} />
                <YAxis axisLine={false} tickLine={false} ticks={[0, 10, 20, 30, 40, 50, 60 ]} />
                <Tooltip  content={<CustomTooltip />} />
                <CartesianGrid 
                    vertical={false}
                    stroke="#E6EAF3"
                />
                <Bar dataKey="Issues Resolved" stackId="a" fill="#3D3ABB"  barSize={20} />
                <Bar dataKey="Risk Records" stackId="b" fill="#CDD5EB" barSize={20} />
                </BarChart>
            </ResponsiveContainer>
        </div>
        </div>
      </>
    );
  }
}
