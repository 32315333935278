import icon1 from '../assets/images/icon-about-1.png'
import ButtonCheckout from './ButtonCheckout'
import ButtonGetInTouch from './ButtonGetInTouch'

const IconBoxAbout = () => {
    return (
        <>
            <section className="flexbox section med-padding-section radial-bg lazyload-bg">
                <div className="page-wrapper">
                    <div className="page-content-block mb-50">
                        <div className="intro">Our Products & Solutions</div>
                        { /*
                        <h2>We are bringing together the world’s security expertise</h2>
                        <p>In an increasingly interconnected world, protecting your sensitive information from falling into the wrong hands is essential. Our comprehensive Dark Web Monitoring service empowers you to stay one step ahead of cybercriminals and potential threats.</p>
                        */ }
                    </div>
                    <div className="flex-wrap flex-wrap-block col-gap-30 mb-50">
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Real-Time Monitoring</h4>
                                <p>Our advanced tool scans over 10 million dark web pages daily to detect and alert you if your personal data has been compromised.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Identity Theft Protection</h4>
                                <p>With identity theft accounting for over $6 billion in damages annually, our proactive solutions help you stay ahead of cybercriminals.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Instant Security Alerts</h4>
                                <p>Receive real-time notifications whenever your sensitive data is found on the dark web.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Comprehensive Database Search</h4>
                                <p>Leveraging multiple API integrations, our tool performs extensive searches across various databases to provide thorough coverage.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Personal Security Dashboard</h4>
                                <p>A user-friendly interface that offers real-time risk assessments, threat analysis, and personalized security recommendations.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Monthly Security Reports</h4>
                                <p>Stay informed with regular insights into your personal data exposure, potential threats, and expert security advice.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Multi-Device Support</h4>
                                <p>Get alerts across all your devices, including desktop, mobile, and tablet.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>User Education & Awareness</h4>
                                <p>Access a built-in resource center designed to educate you on the latest cybersecurity threats, best practices, and online safety tips.</p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-flex-holder">
                        <ButtonCheckout />
                        <ButtonGetInTouch />
                    </div>
                </div>
            </section>
        </>
    );
}
export default IconBoxAbout