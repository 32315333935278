import { useEffect } from "react";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { Form } from "react-router-dom";
import gridIcon1 from '../assets/images/grid-icon-3.png';
import gridIcon2 from '../assets/images/grid-icon-2.png';
import IconArrowRight from "../assets/svg/icon-arrow-right";

const SupportPage = () =>{

    useEffect(() => {
    }, [] );

    return (
        <>
        <DashboardHeader />
        <div className="d-wrapper">
            <DashboardPageTitle title="Support" />
            <div className="d-block d-semi-large layout-2">
                <div className="wrap">
                    <div className="how-can-help-block">
                        <Form className="how-can-help-form">
                            <h2>How can we help you?</h2>
                            <div className="form-field-block">
                                <input type="search" id="search" name="search" placeholder="Type your questions here" />
                            </div>
                        </Form>
                    </div>
                    <div className="help-grid-boxes grid-icon-boxes flex-justify-between flex-wrap flex-wrap-block col-gap-30">
                    <div className="grid-icon-box one-half">
                            <div className="icon"><img loading="lazy" src={ gridIcon1 } alt="" className="img-as-block" /></div>
                            <h3>Data Breaches</h3>
                            <p>In 2022, an average of 4 data breaches occurred daily, exposing sensitive information.</p>
                            <div className="learn-more">
                                <a href="/">8 Articles
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                    <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="grid-icon-box one-half">
                            <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                            <h3>Identity Theft</h3>
                            <p>As already mentioned, Identity theft accounts for over $6 billion in damages every year." This underscores the importance of the product.</p>
                            <div className="learn-more">
                                <a href="/">11 Articles
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                    <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        
                        <div className="grid-icon-box one-half">
                            <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                            <h3>Identity Theft</h3>
                            <p>As already mentioned, Identity theft accounts for over $6 billion in damages every year." This underscores the importance of the product.</p>
                            <div className="learn-more">
                                <a href="/">11 Articles
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                    <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        
                        <div className="grid-icon-box one-half">
                            <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                            <h3>Identity Theft</h3>
                            <p>As already mentioned, Identity theft accounts for over $6 billion in damages every year." This underscores the importance of the product.</p>
                            <div className="learn-more">
                                <a href="/">11 Articles
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"/>
                                    <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-block d-multiple-blocks-small layout-2">
            <div className="wrap">
                    <h3>Security Feed</h3>
                    <ul className="linklist-with-bg-and-arrow">
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="wrap">
                    <h3>Cyber Security News Sources</h3>
                    <ul className="linklist-with-bg-and-arrow">
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <span className="label">Velit eget molestie eu fames vitae. Eu purus lectus vivamus arcu sed massa.</span>
                                <span className="date">Aug, 28, 2022</span>
                                <span className="icon"><IconArrowRight /></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}
export default SupportPage