
import { useEffect } from "react";
import IconArrowLeft from "../assets/svg/icon-arrow-left";
import IconCheck from "../assets/svg/icon-check";
import IconGlobe from "../assets/svg/icon-globe";
import Icon3Dots from "../assets/svg/icon-threedots";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { getSingleReport } from "../helper";
import { useParams } from "react-router-dom";

const AlertsLivefeedPage = () => {

    let params = useParams();
    let firstload = 1
    useEffect(() => {
        if( firstload === 1){
            getSingleReport(params.id, params.notificationid )
        }
        firstload++
    } );
    
    return (
        <>
        <DashboardHeader active="alerts" />
        <div className="d-wrapper position-relative">
            <DashboardPageTitle title="" show="hide" />
            <div className="back-to-previous-page report-link">
                <a href="/dashboard/alerts"><IconArrowLeft /> Back to Alerts</a>
            </div>
            <div className="d-block email-report-page layout-2">
                <h2><span id="email-report-id">Report</span></h2>
                <div className="wrap position-relative">
                    <div className="d-block-header">
                        <div className="value" id="asset-title"></div>
                        <div className="report-number-and-scan text-right">
                            <div className="report-number" id="report-number"></div>
                            <div className="last-scanned" id="email-report-last-scanned"></div>
                        </div>
                    </div>

                    
                    <div className="full-page-loader" id="full-page-loader">
                            <div className="loader-spinner">
                                <span className="loader big"></span>
                            </div>
                    </div>


                    <div className="flex-wrap flex-justify-between large-score-area">
                        <div className='credit-last-scan'>
                            <div className='label'>
                                We received a notification that this asset just got breached.<span className='numer-of-risks' id="number-of-risks" style={ { display: 'none' }}>1 record for this asset</span>
                                <div className='last-scan-text'>Last scanned: 1:05PM - Feb 10, 2024</div>
                            </div>
                        </div>
                        <div className="large-score">
                            <div className="value" id="asset-score" style={ { display: 'none' }}>0/10</div>
                            <div className="" id="asset-risk-type" style={ { display: 'none' }}>
                                <div className="risk-type low">Low Risk</div>
                            </div>
                            
                            <div className="">
                                <div className="risk-type high">High Risk</div>
                            </div>
                        </div>

                    </div>

                    <div className="risk-records-blocks livefeed">
                        <div className="risk-record-block">
                            <div className="risk-record-name">
                                <div className="icon"><IconGlobe /></div>
                                <div className="labels">
                                    <div className="title" data-exposure-name></div>
                                    <div className="url" data-exposure-url></div>
                                </div>
                            </div>
                            <div className="risk-score-and-type flex-justify-end">
                                <div className="risk-score" data-exposure-type></div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="risk-records-blocks pos" style={ { display: 'none' }}>
                        <div id="risk-record-blocks"></div>
                        <div className="risk-record-block">
                            <div className="risk-record-name">
                                <div className="icon"><IconGlobe /></div>
                                <div className="labels">
                                    <div className="title">Phising Site</div>
                                    <div className="url">www.phismenow.com</div>
                                </div>
                            </div>
                            <div className="risk-score-and-type flex-justify-end">
                                <div className="risk-score">7/10</div>
                                <div className="risk-type">High Risk</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="d-block protection-options layout-2">
                <div className="risk-single-actions hide-visibility" id="risk-action-type">
                    <a href="/" className="options"><Icon3Dots /></a>
                    <a href="/" className="clean-this">Clean<IconArrowLeft /></a>
                </div>
                <div className="table-setup-email-reports">
                    <div className="table-setup-email-report flex-wrap flex-justify-between flex-align-center">
                        <div className="th">Status</div>
                        <div className="td">High Risk</div>
                        <div className="td" id="risk-type-status-text" style={ { display: 'none' }}><span className="inline-loader position-relative"><span className="loader-spinner"><span className="loader"></span></span></span></div>
                    </div>
                    <div className="table-setup-email-report action-require-block flex-wrap flex-justify-between flex-align-center">
                        <div className="th">Action <span>required:</span></div>
                        <div className="td" id="risk-action-required"><span className="inline-loader position-relative"><span className="loader-spinner"><span className="loader"></span></span></span></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default AlertsLivefeedPage