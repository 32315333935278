import React, { useEffect, useState } from 'react';
import { mongodbURL } from '../helper';

const TwoFactorAuthPrompt = ({ onVerify }) => {
    const [code, setCode] = useState('');
    const [rememberDevice, setRememberDevice] = useState(false);

    const handleVerify = () => {
        onVerify(code, rememberDevice);
    };

    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrSecret, setQRSecret] = useState('');

    useEffect(() => {
        // Fetch QR code URL from the backend API
        const fetchQRCode = async () => {
            let email = localStorage.getItem('lastLoginEmail')
            const response = await fetch(mongodbURL + '2fa/setup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email : email }),
            });
            const data = await response.json();
            //console.log(data);
            setQrCodeUrl(data.qrCodeUrl); // Update the QR code URL
            setQRSecret(data.secret); // Update the QR code URL
        };
        fetchQRCode();
    }, []);

    return (
        <div>
            <h3 className="text-center">Two-Factor Authentication</h3>
            <p className="text-center">Scan this QR code with your authenticator app</p>
            <div className="form-block">
                <p id="response-text"></p>
                {qrCodeUrl ? (
                    <img src={qrCodeUrl} alt="QR Code" style={{ 'margin': '0 auto', 'display' : 'block' }} />
                ) : (
                    <p>Loading QR Code...</p>
                )}
                <div className="form-field" style={{ 'margin': '0' }}>
                    <input
                        type="text"
                        className="input-text"
                        placeholder="Enter the code from your authenticator app"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>
                <div className='add-asset-container'>
                    <div className="form-field checkbox-field remember-me" style={{ marginTop : '20px' }}>
                        <input
                            type="checkbox"
                            checked={rememberDevice}
                            id="checkbox1"
                            onChange={(e) => setRememberDevice(e.target.checked)}
                        />
                        <label htmlFor="checkbox1"><span></span><p style={{ fontSize : '18px', 'color' : '#000' }}>Remember this device</p></label>
                    </div>

                </div>

                <div className="form-field submit" style={{ marginTop : '20px' }}>
                    <button className="btn blue-btn full-btn" onClick={handleVerify}><span>Verify</span></button>
                </div>
            </div>
            <input
                type="hidden"
                id="qrSecret"
                value={qrSecret}
            />
        </div>
    );
};

export default TwoFactorAuthPrompt;
