import Slider from "react-slick";
import AuthorImage1 from "../assets/images/author-image-1.jpg"
import AuthorImage2 from "../assets/images/author-image-2.jpg"
import AuthorImage3 from "../assets/images/author-image-3.png"
import AuthorImage4 from "../assets/images/author-image-4.png"
import AuthorImage5 from "../assets/images/author-image-5.png"
import AuthorImage6 from "../assets/images/author-image-6.png"
import ratingStars from "../assets/images/ratings-stars.png"

const SliderCustomers = () =>{
    var settings = {
        dots: true,
        auto: false,
        infinite: false,
        speed: 300,
        arrows:false,
        slidesToShow: 2,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 1120,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
            }
            }
        ]
    };
    return (
        <>
        <section className="has-border-top flexbox section slider-block landing-page-v2 v2-r2 ">
            <div className="wrapper">
                <div className="flex-wrap col-gap-30 content-right">
                    <div className="one-half">
                        <div className="content-block width-2">
                            <div className="intro">Customers</div>
                            <h2>We have changed the game of Cyber Security—hear it from our satisfied customers.</h2>
                        </div>
                    </div>
                    <div className="one-half">
                        <div className="offset-content-1">
                        <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower individuals and families with the tools and information needed to defend against cyber-attacks, data breaches, and identity theft.</p>
                        </div>
                    </div>
                </div>
                <div className="slider-area-wrapa">
                    <div className="slideshow-masterb">
                    <Slider {...settings}>
                    <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>CyberAlerts has given me peace of mind as a parent.</h4>
                                        <p className="med">"As a mom of two, keeping my family safe online is a top priority. CyberAlerts made it easy to protect all our devices, and I love that they monitor everything from identity theft to dark web leaks. I can finally rest easy knowing my family’s personal information is secure.”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage1 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Sarah J.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>A must-have for anyone concerned about their online safety!</h4>
                                        <p className="med">"I used to think cyber threats were only something businesses needed to worry about, but after a personal data scare, I signed up for CyberAlerts. Now I get real-time alerts if anything suspicious pops up, and their identity theft protection gives me extra reassurance.”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage2 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">James W.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Great for keeping track of personal and financial security!</h4>
                                        <p className="med">"As a dad and a small business owner, I needed something reliable to protect both my personal and professional data. CyberAlerts has been fantastic. I love the monthly reports that show me exactly where my data stands, and the real-time alerts are a game-changer.”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage3 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Mike L.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Finally, I feel safe online.</h4>
                                        <p className="med">"I’ve always been concerned about hackers and identity theft, especially as I do so much online shopping. CyberAlerts gave me the protection I was looking for, and I appreciate the easy-to-use dashboard where I can monitor everything myself. Their support team has also been super helpful!”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage4 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Emily K.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>The best protection for the whole family!</h4>
                                        <p className="med">"My kids are getting older and spending more time online, so I needed a solution that covered all our devices and kept everyone safe. CyberAlerts provides exactly that, and the parental controls give me confidence that they’re browsing safely. It’s an essential tool for any modern family.”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage5 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Melissa D.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>I caught a potential identity theft issue before it got worse.</h4>
                                        <p className="med">"CyberAlerts notified me that my personal data had been found on the dark web, and I was able to take action right away before any real damage was done. The service is worth every penny, and I can’t recommend it enough to anyone who wants to stay ahead of cybercriminals.”</p>
                                        <div className="author">
                                            <div className="author-image">
                                            <img src={ AuthorImage6 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Tom S.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Slider>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default SliderCustomers