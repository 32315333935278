import { useEffect } from "react";
import IconAssets from "../assets/svg/icon-assets";
import IconEmail from "../assets/svg/icon-email";
import IconLock from "../assets/svg/icon-lock";
import IconPhone from "../assets/svg/icon-phone";
import IconPlus from "../assets/svg/icon-plus";
import { addAssets, getAssets } from "../helper";

const DashboardSectionAssets = ({ title }) =>{
    
    useEffect(() => {
        getAssets()
    }, [] );
    return (
        <>
        <div className="d-block d-assets layout-1 position-relative show-title-bar-loader" id="dashboard-block-assets">
            <div className="wrap">
                <h3><a href="/dashboard/assets"><IconAssets /> Assets</a></h3>
                
                <div className="loader-spinner">
                    <span className="loader big"></span>
                </div>
                <a href="/" className="add-new-item" id="add-new-asset-btn" onClick={ addAssets }>
                    <IconPlus />
                </a>
                <ul className="asset-list-records">
                    <li>
                        { /* ?assets=passwords */ }
                        <a href="/dashboard/assets" className="asset-list-item">
                            <span className="icon"><IconLock /></span>
                            <span className="label"><span id="asset-list-total-passwords">0</span></span>
                        </a>
                    </li>
                    <li>
                    { /* ?assets=phones */ }
                        <a href="/dashboard/assets" className="asset-list-item">
                            <span className="icon"><IconPhone /></span>
                            <span className="label"><span id="asset-list-total-phones">0</span></span>
                        </a>
                    </li>
                    <li>
                    { /* ?assets=emails */ }
                        <a href="/dashboard/assets" className="asset-list-item">
                            <span className="icon"><IconEmail /></span>
                            <span className="label"><span id="asset-list-total-emails">0</span></span>
                        </a>
                    </li>
                    <li style={{ 'display' : 'none' }}>
                    { /* ?assets=identities */ }
                        <a href="/dashboard/assets" className="asset-list-item">
                            <span className="icon"><IconAssets /></span>
                            <span className="label"><span id="asset-list-total-identities">0</span> Identities</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </>
    );
}
export default DashboardSectionAssets