import { CardElement, useRecurly } from "@recurly/react-recurly";
import React from "react";
import { fetchData, mongodbURL } from "../helper";

export function UpgradeAccountRecurly (props) {
    const formRef = React.useRef();
    const recurly = useRecurly();

    const submitForm = async(token) => {
        let email = fetchData('userName')
        let currentSubID = document.querySelector('#ca_subscription_id').value
        let result = await fetch(
        mongodbURL + 'upgrade-my-plan', {
            method: "post",
            body: JSON.stringify({ email , token, currentSubID }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();
        console.warn(result);
        if( result ){
            if( result.message === 'success' ){
                window.location.href = '/upgrade-thank-you';
            }
        }
    }

    const setRecurlyPaymentMethod = (e) =>{
        document.querySelector('#paymentMethod').value = 'recurly'
        document.querySelectorAll('[data-paymentmethod="recurly"]').forEach(el => {
            el.classList.add('active')
        });
        document.querySelectorAll('[data-paymentmethod="paypal"]').forEach(el => {
            el.classList.remove('active')
        });
    }
    const setPaypalPaymentMethod = (e) =>{
        document.querySelector('#paymentMethod').value = 'paypal'
        document.querySelectorAll('[data-paymentmethod="recurly"]').forEach(el => {
            el.classList.remove('active')
        });
        document.querySelectorAll('[data-paymentmethod="paypal"]').forEach(el => {
            el.classList.add('active')
        });
    }

    async function handleSubmit () {

        let paymentMethod = document.querySelector('#paymentMethod').value;

        if( paymentMethod === 'recurly'){
            console.log(formRef.current);
            recurly.token(formRef.current, (err, token) => {
            if (err) {
                console.log(err)
                let extraErrorMessage = ''
                if( err.fields.length ){
                if(err.fields.includes('number') || err.fields.includes('number')  || err.fields.includes('number') ){
                    extraErrorMessage = '<br/> Please Enter a Valid Credit Card.'
                }
                }
                document.querySelector('#error-message-reponse').innerHTML = err.message + extraErrorMessage;
                document.querySelector('#modal-pop-bg-error').classList.toggle('fadeIn')
                document.querySelector('#modal-errors').classList.toggle('fadeIn')
            } else {
                console.log('token', token);
                submitForm(token.id)
                //document.querySelector('#modal-pop-bg-processing').classList.toggle('fadeIn')
                //document.querySelector('#modal-processing').classList.toggle('fadeIn')
            }
            });
        } else {
            
            let email = fetchData('userName')
            let result = await fetch(
            mongodbURL + 'upgrade-paypal-plans', {
                method: "post",
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = await result.json();

            if( result ){
                console.log(result)
                window.location.href = result.redirect;
            }
        }
    
      }


    return(<>
        
        <div className="payment-method-type active" onClick={ setRecurlyPaymentMethod } data-paymentmethod="recurly"><span></span>Recurly</div>
        <div className="payment-method-type" onClick={ setPaypalPaymentMethod } data-paymentmethod="paypal"><span></span>Paypal</div>
        <form ref={formRef}>
            <div className="order-items-next active" data-paymentmethod="recurly">
            <CardElement
                style={{
                    fontSize: '16px',
                    color: '#333',
                    backgroundColor: '#dddddd',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    ':focus': { borderColor: '#007bff' }, // Focused state
                    '::placeholder': { color: '#888' }, // Placeholder color
                    '.invalid': { borderColor: 'red' } // Error state
                }}
            />
            </div>
        <input type="hidden" value={ fetchData('userName') } name="firstname" id="firstname" data-recurly="first_name" />
        <input type="hidden" value="CyberAlerts" name="lastname" id="lastname" data-recurly="last_name" />
        <input type="hidden" value="14th Street" name="address" id="address" data-recurly="address1" />
        <input type="hidden" value="New York" name="city" id="city" data-recurly="city" />
        <input type="hidden" value="US" name="country" id="country" data-recurly="country" />
        <input type="hidden" value="NY" name="state" id="state" data-recurly="state" />
        <input type="hidden" value="10004" name="postal_code" id="postal_code" data-recurly="postal_code" />
        <input type="hidden" value="" name="ca_subscription_id" id="ca_subscription_id" />
        <input type="hidden" value="recurly" name="paymentMethod" id="paymentMethod" />

        <div className="order-items-next active" >
            <button type="button" className="btn blue-btn btn-next" onClick={ handleSubmit }>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25781 20.3828C5.07812 18.625 0.625 13.2734 0.625 5.5C0.625 4.75781 1.05469 4.09375 1.75781 3.78125L9.25781 0.65625C9.45312 0.578125 9.76562 0.539062 10 0.539062C10.1953 0.539062 10.5078 0.578125 10.7031 0.65625L18.2031 3.78125C18.9062 4.09375 19.375 4.75781 19.375 5.5C19.375 14.1719 14.0625 18.9766 10.7031 20.3828C10.2344 20.5781 9.72656 20.5781 9.25781 20.3828ZM10 2.375L2.5 5.5C2.5 12.1406 6.17188 17.0625 10 18.625C13.6328 17.1016 17.5 12.2969 17.5 5.5L10 2.375Z" fill="white"/>
                </svg>
                <span id="continue-label" className="label">Upgrade My Plan</span>
            </button>
            <div className="policy-text text-center">By clicking Upgrade you agree to CyberAlerts <a href="/terms-and-conditions">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>.</div>
        </div>
        </form>
    </>)
}