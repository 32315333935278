import { useEffect } from "react";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import { Form, useActionData } from "react-router-dom";
import { mongodbURL } from "../helper";


const AcceptableUsePage = ({ classes }) =>{

    const data = useActionData()

    useEffect(() => {
        document.title = "Acceptable Use - Protect Your Identity with CyberAlerts";
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );


    return (
        <>
            <div className={ classes }>
                <Nav />
                <section className="section flexbox">
                    <div className="wrapper">
                        <div className="small-page-content-block">
                            <div className="page-content-block text-center">
                                <h1 className="med">Acceptable Use</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="section flexbox small-padding-section">
                <div className="page-wrapper">
                    <div className="page-content-block med-gap">
                    <h2> General Info</h2>
                    <p>To uphold the quality and reputation of CyberAlerts products and services, your use of CyberAlerts (the “Service”) is subject to these Acceptable Use policies. If you are found to be in violation of our policies at any time, as determined by CyberAlerts in its sole discretion, we may warn you or suspend or terminate your account. Please note that we may change our program policies at any time, and pursuant to the CyberAlerts Terms of Service (the “Terms”), it is your responsibility to keep up-to-date with and adhere to the policies posted here. All capitalized terms used herein have the meanings stated in the Terms, unless stated otherwise.
                    </p>
                    <h2>Prohibited Content</h2>
                    <p>The Content displayed and/or processed through your Application or other web site utilizing the Service shall not contain any of the following types of content:
                    </p>
                    <ol>
                        <li>Content that infringes a third party’s rights (e.g., copyright) according to applicable law;</li>
                        <li>Hate-related or violent content;</li>
                        <li>Content advocating racial or ethnic intolerance;</li>
                        <li>Content intended to advocate or advance computer hacking or cracking;</li>
                        <li>Other illegal activity, including without limitation illegal export of controlled substances or illegal software;</li>
                        <li>Drug paraphernalia;</li>
                        <li>Phishing;</li>
                        <li>Malicious content;</li>
                        <li>Other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third-party rights.</li>
                    </ol>
                    <h2>Prohibited Content</h2>
                    <p>In addition to (and/or as some examples of) the violations described in the Terms, you may not and may not allow any third party, including your end users, to:</p>
                    <ol>
                        <li>Generate or facilitate unsolicited commercial email (“spam”). Such activity includes, but is not limited to:</li>
                        <li>Sending email in violation of the CAN-SPAM Act or any other applicable anti-spam law;</li>
                        <li>Imitating or impersonating another person or his, her or its email address, or creating false accounts for the purpose of sending spam;</li>
                        <li>Data mining any web property (including CyberAlerts) to find email addresses or other user account information;</li>
                        <li>Sending unauthorized mail via open, third-party servers;</li>
                        <li>Sending emails to users who have requested to be removed from a mailing list;</li>
                        <li>Selling, exchanging or distributing to a third party the email addresses of any person without such person’s knowing and continued consent to such disclosure; and</li>
                        <li>Sending unsolicited emails to significant numbers of email addresses belonging to individuals and/or entities with whom you have no preexisting relationship.</li>
                        <li>Send, upload, distribute or disseminate or offer to do the same with respect to any unlawful, defamatory, harassing, abusive, fraudulent, infringing, obscene, or otherwise objectionable content</li>
                        <li>Intentionally distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;</li>
                        <li>Conduct or forward pyramid schemes and the like;</li>
                        <li>Transmit content that may be harmful to minors;</li>
                        <li>Impersonate another person (via the use of an email address or otherwise) or otherwise misrepresent yourself or the source of any email;</li>
                        <li>Illegally transmit another’s intellectual property or other proprietary information without such owner’s or licensor’s permission;</li>
                        <li>Use the Service to violate the legal rights (such as rights of privacy and publicity) of others;</li>
                        <li>Promote or encourage illegal activity;</li>
                        <li>Interfere with other users’ enjoyment of the Service;</li>
                        <li>Sell, trade, resell or otherwise exploit the Service for any unauthorized commercial purpose;</li>
                        <li>Modify, adapt, translate, or reverse engineer any portion of the Service;</li>
                        <li>Remove any copyright, trademark or other proprietary rights notices contained in or on the Service;</li>
                        <li>Reformat or frame any portion of the web pages that are part of the Service’s Administration Console;</li>
                        <li>Use the Service in connection with illegal peer-to-peer file sharing;</li>
                        <li>Display any content on the Service (including but not limited to the customizable login page) that contains any pornographic, hate-related or violent content or contain any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights; or</li>
                        <li>Modify the CyberAlerts logo or any other CyberAlerts Brand Features.</li>
                        <li>Use the Service, or any interfaces provided with the Service, to access any CyberAlerts product or service in a manner that violates the terms of service or other terms and conditions for use of such CyberAlerts product or service.</li>
                    </ol>
                    </div>
                </div>
            </section>
            <section className="section flexbox large-padding-section radial3-bg lazyload-bg">
                <div className="page-wrapper">
                    <div className="flex-wrap mobile-one-block col-gap-30">
                        <div className="one-half">
                            <div className="page-content-block large-gap">
                                <h4>We'd love to hear from you if you have questions about CyberAlerts</h4>
                                <p>Please use the message form to get in touch with our sales, HR, or leadership team. If you are a client seeking support, our team is available via the help desk or by contacting your dedicated account managers.</p>
                                <div>
                                <div className="contact-email"><a href="mailto:support@cyberalerts.net">support@cyberalerts.net</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="one-half contact-form">
                            <h2 className="med">Send us a message</h2>
                            <Form id="contactForm" method="post" className="form-block">
                                { data && data.error && <p>{data.error}</p>}
                                { data && data.message && <div className="form-notification successful">{data.message}</div>}
                                <div className="form-field">
                                    <input type="text" className="input-text" name="firstname" id="firstname" placeholder="First Name" required />
                                </div>
                                <div className="form-field">
                                    <input type="text" className="input-text" name="lastname" id="lastname"  placeholder="Last Name" required />
                                </div>
                                <div className="form-field">
                                    <input type="email" className="input-text" name="email" id="email"  placeholder="Email" required />
                                </div>
                                <div className="form-field">
                                    <input type="text" className="input-text" name="message" id="message"  placeholder="Message" required />
                                </div>
                                <div className="form-field submit">
                                    <button className="btn blue-btn" type="submit">
                                        <span>Send Message</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default AcceptableUsePage