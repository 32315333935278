
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { Form } from "react-router-dom";
import OrderItemImage from "../assets/images/order-item-image.png";
import IconArrowLeft from "../assets/svg/icon-arrow-left";
import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { RECURLY_PUBLIC_API } from "../globals/Constants";
import React from "react";
import { UpgradeAccountRecurly } from "./UpgradeAccountRecurly";
import IconCloseBlue from "../assets/svg/icon-close-blue";
import { ModalClose } from "../helper";


const AccountUpgradePage = () => {

    return (
        <>
        <DashboardHeader active="account" />
        <div id="account-setting-upgrade-page" className="d-wrapper">
            <DashboardPageTitle title="" show="hide" />
            <div className="back-to-previous-page">
                <a href="/dashboard/account"><IconArrowLeft /> Back to Settings</a>
            </div>
            <div className="d-block classic-d-block dashboard-change-password layout-full-width">
                <div className="wrap">
                    <div id="error-text-holder" className="error-text-holder hide-element"></div>
                        <div className="accordion-block billing-settings active" style={{ borderBottom : '0' }}>
                            <div className="accordion-header">
                                <div className="flex-wrap">
                                    <div className="accordion-icon">
                                        <span className="icon-lock">
                                            <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.80435 9.83152C8.55503 9.83152 9.13247 10.4667 9.13247 11.2174C9.13247 12.0258 8.49728 12.6033 7.7466 12.6033C6.99592 12.6033 6.41848 12.0258 6.41848 11.2174C6.41848 10.4667 6.99592 9.83152 7.80435 9.83152ZM15.1957 9.83152C15.9463 9.83152 16.5815 10.409 16.5815 11.2174C16.5815 11.9681 15.9463 12.6033 15.1957 12.6033C14.3872 12.6033 13.8098 11.9681 13.8098 11.2174C13.8098 10.409 14.3872 9.83152 15.1957 9.83152ZM11.5 0.130434C17.6209 0.130434 22.587 5.09647 22.5292 11.2174V26.9817C22.5292 27.5014 22.0673 27.9056 21.6053 27.9056C21.3743 27.9056 21.1433 27.7901 20.9124 27.6168L19.4687 26.5197C19.1223 26.231 18.5448 26.2887 18.2561 26.6929L15.7731 29.4647C15.5999 29.6379 15.3111 29.6957 15.1379 29.6957C14.9069 29.6957 14.676 29.6379 14.445 29.4647L12.1352 26.8084C11.962 26.5774 11.731 26.5197 11.4423 26.5197C11.2113 26.5197 10.9803 26.5774 10.7493 26.8084L8.43954 29.4647C8.2663 29.6379 8.03533 29.6957 7.80435 29.6957C7.51562 29.6957 7.28465 29.6379 7.05367 29.4647L4.57065 26.6929C4.45516 26.462 4.22418 26.3465 3.93546 26.3465C3.76223 26.3465 3.58899 26.4042 3.41576 26.5197L1.97215 27.6168C1.74117 27.7901 1.51019 27.8478 1.33696 27.8478C0.817255 27.8478 0.413043 27.5014 0.413043 26.9817V11.7371C0.413043 5.61617 5.09035 0.361413 11.1535 0.188179C11.269 0.130434 11.3845 0.130434 11.5 0.130434ZM20.7391 25.0761V11.2174C20.7391 6.13587 16.5815 1.97826 11.5 1.97826L11.2113 2.036C6.24524 2.15149 2.26087 6.48234 2.26087 11.6793V25.0761H2.31861C2.78057 24.6719 3.35802 24.4986 3.93546 24.4986C4.74389 24.4986 5.49456 24.8451 6.01427 25.4226L7.80435 27.3859L9.4212 25.5958C9.9409 24.9606 10.6916 24.6719 11.5 24.6719C12.2507 24.6719 13.0014 24.9606 13.5211 25.5958L15.1957 27.3859L16.928 25.4226C17.4477 24.8451 18.1984 24.4986 19.0068 24.4986C19.5842 24.4986 20.1617 24.6719 20.6236 25.0761H20.7391Z" fill="#0E0F0C"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="accordion-title-blocks">
                                        <div className="title"><span className="main-title">Billing</span></div>
                                        <div className="desc">Manage your personal information</div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-content">
                                <div className="dashboard-forms full-width auto-height">
                                    <div className="dashboard-form-fields flex-wrap flex-wrap-block col-gap-20 account-main-form dashboard-form-field-group">
                                        <div className="dashboard-form-field one-half">
                                            <div className="settings-plan-blocks">
                                                <div className="section-block-title">
                                                    <h4>Upgrade Plan</h4>
                                                </div>
                                                <div className="upgrade-order-summary">
                                                    <div className="order-summary-items">
                                                        <div className="order-summary-item">
                                                            <div className="order-summary-image">
                                                                <span className="qty-bubble">1</span>
                                                                <img src={OrderItemImage} alt="" loading="lazy" className="img-as-block" />
                                                            </div>
                                                            <div className="order-summary-details">
                                                                <div className="order-title">Dark Web Monitoring Yearly</div>
                                                                <div className="order-detail">Protect yourself and your family from vulnerabilities</div>
                                                            </div>
                                                            <div className="order-summary-price">
                                                                <div className="order-item-price">179.91</div>
                                                                <div className="order-item-prev-price">per year</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="order-items-content">
                                                        <h4 className="payment-method-type-title">Payment Method</h4>

                                                        <div className="payment-method-container">
                                                            <RecurlyProvider publicKey={ RECURLY_PUBLIC_API }>
                                                                <Elements>
                                                                    <UpgradeAccountRecurly />
                                                                </Elements>
                                                            </RecurlyProvider>

                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { /* accodion block end */}
                </div>
            </div>
        </div>

        <div id="modal-pop-bg-error" className="modal-bg">
            <div id="modal-errors" className="modal-content">
            <a href="/" id="modal-close-identity" className="modal-close" onClick={ ModalClose }><IconCloseBlue /></a>
                <div className="wrap">
                    <h3 id="error-message-reponse">Please Try Again.</h3>
                </div>
            </div>
        </div>

        <div id="modal-pop-bg-processing" className="modal-bg">
            <div id="modal-processing" className="modal-content">
                <div className="wrap">
                    <h3>Processing Upgrade Plan</h3>
                    <div id="scanning-asset-text-response" className="text-center">Processing...</div>
                    <div className="position-relative" style={{ height: '250px' }}>
                        <div className="loader-spinner">
                            <span className="loader big"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}
export default AccountUpgradePage