import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import React from "react"
import { MyPaymentForm } from './recurly-payment-form';
import '../dashboard/assets/dashboard-responsive.css'
import { RECURLY_PUBLIC_API } from '../globals/Constants';

const CheckoutSignupV2 = ({ classes }) =>{
    return (
        <>
        <RecurlyProvider publicKey={ RECURLY_PUBLIC_API }>
            <Elements>
                <MyPaymentForm />
            </Elements>
        </RecurlyProvider>
        </>
    )
}

export default CheckoutSignupV2