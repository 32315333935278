import logo from '../assets/images/logo.png'
import footerTrustedLogo from '../assets/images/footer-trust-logos.png'
import EmailLink from '../globals/EmailLink';

const Footer = () =>{
    return (
        <>
        <footer id="main-footer" className="">
            <div className="top-footer section">
                <div className="wrapper">
                    <div className="flex-wrap main-flex flex-justify-between">
                        <div className="footer-logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></div>
                        <div className="footer-menus">
                            <div className="flex-wrap footer-menu-flex">
                                <div className="footer-menu">
                                    <h5 style={ { display: "none" }}>Cyber Security</h5>
                                    <ul>
                                        <li><a href="/dark-web-monitoring">Dark Web Monitoring</a></li>
                                        <li><a href="/identity-theft-protection">Identity Theft Protection</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu">
                                    <h5 style={ { display: "none" }}>Product</h5>
                                    <ul>
                                        <li><a href="/about">Features</a></li>
                                        <li><a href="/pricing">Pricing</a></li>
                                        <li><a href="/login">Login</a></li>
                                        <li><a href="/faq">FAQ</a></li>
                                        <li><a href="/checkout">Signup</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu">
                                    <h5 style={ { display: "none" }}>Company</h5>
                                    <ul>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                        <li><a href="/blog">Articles</a></li>
                                        <li><a href="/contact">Careers</a></li>
                                    </ul>
                                </div>
                                <div className="footer-menu" style={ { display: "none" }}>
                                    <h5 >Legal</h5>
                                    <ul>
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        <li><a href="/terms-and-conditions">Terms of Use</a></li>
                                        <li><a href="/blog">Articles</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-contacts">
                            <div><EmailLink /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section bottom-footer">
                <div className="wrapper">
                    <div className="btn-flex-holder">
                        <a href="/pricing" className="get-protected-footer btn black-btn">
                            <span>Get Protected</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="#000000"></path>
                            <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="#000000"></path>
                            </svg>
                        </a>
                    </div>
                    
                    <div className="footer-trust-logos">
                        <img loading="lazy" src={ footerTrustedLogo } alt="" className="img-as-block" />
                    </div>
                    
                    <div className="copyright">
                        <div className="copy-top">
                        Copyright &copy; {(new Date().getFullYear())} CyberAlerts Inc. All Rights Reserved.
                        </div>
                        <div className="copy-links-inline"><a href="https://cyberalerts.net/acceptable-use">Acceptable Use</a> | <a href="https://cyberalerts.net/privacy-policy">Privacy Policy</a> | <a href="https://cyberalerts.net/terms-and-conditions">Terms of Use</a></div>
                    </div>
                    
                </div>
            </div>
        </footer>
        </>
    );
}
export default Footer