const BannerCBox1 = () =>{
    return (
        <>
        <section className="has-border-top flexbox section">
            <div className="wrapper">
                <div className="flex-wrap col-gap-30 content-right">
                    <div className="one-half">
                        <div className="content-block">
                            <div className="intro">Understand, Assess, and Assure.</div>
                            <h2>We provide round-the-clock availability, technical support & monitoring.</h2>
                            <p>At CyberAlerts, we are committed to safeguarding the digital world by providing comprehensive Cyber Security solutions to individuals and families. With the ever-evolving landscape of cyber threats, we understand the critical importance of protecting your valuable digital assets and personal information.  </p>
                        </div>
                    </div>
                    <div className="one-half"></div>
                </div>
                <div className="custom-block-style-1 box-style-1">
                    <div className="styled-content">
                        <h3>Fraud & account takeovers cost billions of dollars every year.</h3>
                        <div className="flex-wrap col-gap-30 number-grid">
                            <div className="one-third">
                                <div className="number-block">231,743</div>
                                <p className="small">breached credentials recovered per year by CyberAlerts</p>
                            </div>
                            <div className="one-third">
                                <div className="number-block">72%</div>
                                <p className="small">of users reuse passwords across accounts</p>
                            </div>
                            <div className="one-third">
                                <div className="number-block">80%</div>
                                <p className="small">of breaches involve stolen credentials</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default BannerCBox1