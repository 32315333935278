import React, { useState } from 'react';
import { useRecurly, CardElement } from '@recurly/react-recurly';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { RECURLY_PUBLIC_API } from '../globals/Constants';

const CompleteRecurlyForm = () => {
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const recurly = useRecurly();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Recurly Environment:', recurly.environment);

    if (!recurly) {
      setError('Recurly instance not initialized.');
      return;
    }

    // Tokenize the form data
    recurly.token(document.querySelector('form'), (err, token) => {
      if (err) {
        setError(err.message);
        setToken(null);
      } else {
        setToken(token.id);
        setError(null);
        console.log('Payment Token:', token.id);
        // Send this token to your backend for further processing
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="card">Card Details</label>
        <div id="card">
          <CardElement
            onChange={(event) => setError(event.error ? event.error.message : null)}
          />
        </div>
      </div>

      {error && <div style={{ color: 'red' }}>Error: {error}</div>}
      {token && <div style={{ color: 'green' }}>Payment Token: {token}</div>}

      <button type="submit">Submit Payment</button>
    </form>
  );
};

const RecurlyPayment = () => {
  if (!RECURLY_PUBLIC_API) {
    console.error('Missing Recurly Public API Key!');
    return <div>Error: Missing Recurly Public API Key.</div>;
  }

  return (
    <RecurlyProvider publicKey={RECURLY_PUBLIC_API}>
      <Elements>
        <CompleteRecurlyForm />
      </Elements>
    </RecurlyProvider>
  );
};

export default RecurlyPayment;
